import { useEffect, useState } from "react";
// material
import { Container } from "@mui/material";

import { PATH_DASHBOARD } from "../../../routes/paths";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import ProfileForm from "./ProfileForm";
import LoadingScreen from "../../../components/LoadingScreen";
import ErrorMsg from "../../../components/ErrorMsg";
import useHttp from "../../../hooks/useHttp";

// ----------------------------------------------------------------------
let isFirstTime = true;
export default function Profile() {
  const [user, setUser] = useState({});
  const { isLoading, error, sendRequest: fetchUser } = useHttp();

  useEffect(() => {
    if(isFirstTime){
      fetchUser({ url: "profile", method: "GET" }, (data) => {
        setUser(data);
      });
      isFirstTime = false;
    }
  }, [fetchUser]);

  if (isLoading) {
    return (
      <LoadingScreen
        sx={{
          height: "80vh",
        }}
      />
    );
  }

  if (error) {
    return <ErrorMsg msg={error} action={fetchUser} />;
  }

  return (
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Edit Profile"
        links={[
          { name: "Home", href: PATH_DASHBOARD.root },
          { name: "Edit Profile" },
        ]}
      />
      <ProfileForm currentUser={user} />
    </Container>
  );
}
