import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import checkmarkFill from '@iconify/icons-eva/checkmark-circle-2-fill';
import downloadFill from '@iconify/icons-eva/download-fill';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import swal from 'sweetalert';
// material
import { Stack, Button, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CreateEditAssessment from './CreateEditAssessment';
import AssessmentEmail from './AssessmentEmail';
import useHttp from '../../../../hooks/useHttp';
import useAssessments from '../../../../hooks/useAssessments';

// ----------------------------------------------------------------------

AssessmentTableActions.propTypes = {
  id: PropTypes.string,
  isFinished: PropTypes.bool,
  markFinished: PropTypes.func,
  title: PropTypes.string,
  companyId: PropTypes.string,
  showPreviousScore: PropTypes.bool,
  companyTitle: PropTypes.string,
};

export default function AssessmentTableActions({ id, isFinished, title, companyId, showPreviousScore, companyTitle }) {
  const [isLoading, setIsLoading] = useState(false);
  const { sendRequest } = useHttp();
  const { markAsFinished } = useAssessments();

  const markFinishedHandler = (action) => {
    swal({
      title: 'Are you sure?',
      text: `You want to finish ${title}?`,
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: { text: 'Mark finished', className: 'bg-info' },
      },
    }).then((isMarkedFinished) => {
      if (isMarkedFinished) {
        markAsFinished(companyId, id);
      }
    });
  };

  const downloadReportHandler = async () => {
    setIsLoading(true);

    await sendRequest({ url: `companies/${companyId}/assessments/${id}/scores`, method: 'GET' }, (resData) => {
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

      const new_workbook = XLSX.utils.book_new();
      if (resData.assessments) {
        const worksheet = XLSX.utils.aoa_to_sheet(resData.assessments);
        XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Assessments');
      } else {
        resData.employees.forEach((employee) => {
          const worksheet = XLSX.utils.aoa_to_sheet(employee.assessment);
          XLSX.utils.book_append_sheet(new_workbook, worksheet, `${employee.employee.firstName} ${employee.employee.lastName}`);
        });
      }

      const excelBuffer = XLSX.write(new_workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, `${resData.assessment.companyAssessmentTitle} ${companyTitle}.xlsx`);
    });

    setIsLoading(false);
  };

  return (
    <Stack direction="row" justifyContent="flex-end">
      {!isFinished && (
        <Fragment>
          <CreateEditAssessment
            companyId={companyId}
            currentAssessment={{
              showPreviousScore: showPreviousScore,
              companyAssessmentId: id,
              companyAssessmentTitle: title,
            }}
          />
          <AssessmentEmail companyId={companyId} companyTitle={companyTitle} companyAssessmentId={id} />
          <Tooltip title="Mark Finished">
            <Button
              color="success"
              size="small"
              variant="contained"
              onClick={markFinishedHandler}
              sx={{ ml: 1, color: '#ffffff', maxWidth: 40, maxHeight: 40, minWidth: 40, minHeight: 40 }}
            >
              <Icon icon={checkmarkFill} width={40} height={40} />
            </Button>
          </Tooltip>
        </Fragment>
      )}
      <Tooltip title="Download Report">
        <LoadingButton
          size="small"
          color="secondary"
          loading={isLoading}
          variant="contained"
          onClick={downloadReportHandler}
          sx={{ ml: 1, color: '#ffffff', maxWidth: 40, maxHeight: 40, minWidth: 40, minHeight: 40 }}
        >
          <Icon icon={downloadFill} width={40} height={40} />
        </LoadingButton>
      </Tooltip>
    </Stack>
  );
}
