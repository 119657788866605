import { useEffect } from "react";
import { useParams, useLocation,useNavigate } from "react-router-dom";
// material
import { Container } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import CompanyForm from "./CompanyForm";
import LoadingScreen from "../../../components/LoadingScreen";
import useCompanies from "../../../hooks/useCompanies";
import ErrorMsg from "../../../components/ErrorMsg";

// ----------------------------------------------------------------------
let isFirstTime = true;
export default function CompanyCreate() {
  const { companyDetails, isLoading, fetchCompanies, error } = useCompanies();
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const isEdit = pathname.includes("edit");

  useEffect(() => {
    if (isLoading && isFirstTime) {
      fetchCompanies();
    }
  }, [fetchCompanies, isLoading]);

  if (isLoading) {
    return (
      <LoadingScreen
        sx={{
          height: "80vh",
        }}
      />
    );
  }

  if (error) {
    return <ErrorMsg msg={error} action={fetchCompanies} />;
  }

  const company = companyDetails(id);
  
  if(isEdit && !company){
    navigate('/404');
    return <h2>Not Found!</h2>
  }

  return (
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading={!isEdit ? "Create a new company" : "Edit company"}
        links={[
          { name: "Home", href: PATH_DASHBOARD.root },
          {
            name: "Companies",
            href: PATH_DASHBOARD.companies.root,
          },
          {
            name: !isEdit ? "New company" : company.companyTitle,
          },
        ]}
      />

      <CompanyForm isEdit={isEdit} currentCompany={company} />
    </Container>
  );
}
