import { useEffect } from "react";
import { useParams, useLocation,useNavigate } from "react-router-dom";
// material
import { Container } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import TemplateForm from "./TemplateForm";
import LoadingScreen from "../../../components/LoadingScreen";
import ErrorMsg from "../../../components/ErrorMsg";
import useTemplates from "../../../hooks/useTemplates";

// ----------------------------------------------------------------------
let isFirstTime = true;
export default function TemplateCreate() {
  const { templateDetails, isLoading, fetchTemplates, error } = useTemplates();
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const isEdit = pathname.includes("edit");

  useEffect(() => {
    if (isLoading && isFirstTime) {
      fetchTemplates();
    }
  }, [fetchTemplates, isLoading]);

  if (isLoading) {
    return (
      <LoadingScreen
        sx={{
          height: "80vh",
        }}
      />
    );
  }

  if (error) {
    return <ErrorMsg msg={error} action={fetchTemplates} />;
  }

  const template = templateDetails(id);

  if(isEdit && !template){
    navigate('/404');
    return <h2>Not Found!</h2>
  }

  return (
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading={!isEdit ? "Create a new template" : "Edit template"}
        links={[
          { name: "Home", href: PATH_DASHBOARD.root },
          {
            name: "Templates",
            href: PATH_DASHBOARD.templates.root,
          },
          {
            name: !isEdit ? "New template" : template.templateTitle,
          },
        ]}
      />

      <TemplateForm isEdit={isEdit} currentTemplate={template} />
    </Container>
  );
}
