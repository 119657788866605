// import { Suspense, lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";

// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";

// import LoadingScreen from "../components/LoadingScreen";
import DashboardLayout from "../layouts/dashboard";
import { QuestionCategoriesContextProvider } from "../contexts/question-categories-context";
import { CompaniesContextProvider } from "../contexts/companies-context";
import { EmailTemplatesContextProvider } from "../contexts/email-templates-context";

// Pages
import Login from "../pages/Login";
// import Dashboard from "../pages/Dashboard";
import QuestionCategories from "../pages/QuestionCategories";
import QuestionCategoryCreate from "../pages/QuestionCategories/CreateEdit";
import CompaniesCreate from "../pages/Companies/CreateEdit";
import EmailTemplates from "../pages/EmailTemplates";
import EmailTemplateCreate from "../pages/EmailTemplates/Create";
import EmailTemplateEdit from "../pages/EmailTemplates/Edit";
import Page404 from "../pages/Page404";
import Templates from "../pages/Templates";
import TemplateView from "../pages/Templates/View";
import TemplatesCreate from "../pages/Templates/CreateEdit";
import Companies from "../pages/Companies";
import CompanyView from "../pages/Companies/View";
import Profile from "../pages/User/Profile";
import ChangePassword from "../pages/User/ChangePassword";
import EmailExport from "../pages/EmailExport";
import { QuestionsContextProvider } from "../contexts/questions-context";
import { TemplatesProvider } from "../contexts/templates-context";
import { AssessmentsContextProvider } from "../contexts/assessments-context";
import { EmployeesContextProvider } from "../contexts/employees-context";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "login",
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    // App Routes
    {
      path: "/",
      element: (
        <AuthGuard>
          <QuestionCategoriesContextProvider>
            <EmailTemplatesContextProvider>
              <TemplatesProvider>
                <CompaniesContextProvider>
                  <DashboardLayout />
                </CompaniesContextProvider>
              </TemplatesProvider>
            </EmailTemplatesContextProvider>
          </QuestionCategoriesContextProvider>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/" replace /> },
        { path: "/", element: <Companies /> },
        {
          path: "/question-categories",
          children: [
            { element: <Navigate to="/question-categories" replace /> },
            { path: "/question-categories", element: <QuestionCategories /> },
            { path: "create", element: <QuestionCategoryCreate /> },
            { path: ":id/edit", element: <QuestionCategoryCreate /> },
          ],
        },
        {
          path: "email-templates",
          children: [
            { element: <Navigate to="/email-templates" replace /> },
            { path: "/email-templates", element: <EmailTemplates /> },
            { path: "create", element: <EmailTemplateCreate /> },
            { path: ":id/edit", element: <EmailTemplateEdit /> },
          ],
        },
        {
          path: "/companies",
          children: [
            { element: <Navigate to="/companies" replace /> },
            { path: "/companies", element: <Companies /> },
            {
              path: ":id/view",
              element: (
                <AssessmentsContextProvider>
                  <EmployeesContextProvider>
                    <QuestionsContextProvider>
                      <CompanyView />
                    </QuestionsContextProvider>
                  </EmployeesContextProvider>
                </AssessmentsContextProvider>
              ),
            },
            { path: "create", element: <CompaniesCreate /> },
            { path: ":id/edit", element: <CompaniesCreate /> },
          ],
        },
        {
          path: "email/export",
          element: <EmailExport /> 
        },
        {
          path: "templates",
          children: [
            { element: <Navigate to="/templates" replace /> },
            { path: "/templates", element: <Templates /> },
            {
              path: ":id/view",
              element: (
                <QuestionsContextProvider>
                  <TemplateView />
                </QuestionsContextProvider>
              ),
            },
            { path: "create", element: <TemplatesCreate /> },
            { path: ":id/edit", element: <TemplatesCreate /> },
          ],
        },
        {
          path: "profile",
          element: <Profile />,
        },
        {
          path: "change-password",
          element: <ChangePassword />,
        },
      ],
    },
    { path: "404", element: <Page404 /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
