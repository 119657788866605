import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import swal from "sweetalert";
// material
import { Stack, Button,Tooltip } from "@mui/material";
import useQuestions from "../../../../hooks/useQuestions";
import CreateEditQuestion from "./CreateEditQuestion";

// ----------------------------------------------------------------------

QuestionsTableActions.propTypes = {
  id: PropTypes.string,
  typeId: PropTypes.string,
  currentQuestion: PropTypes.object,
};

export default function QuestionsTableActions({ id, typeId, currentQuestion }) {
  const { deleteQuestion } = useQuestions();
  const deleteButtonHandler = () => {
    swal({
      title: "Are you sure?",
      text: "You want to delete it?",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: { text: "Delete", className: "bg-info" },
      },
    }).then((isDelete) => {
      if (isDelete) {
        deleteQuestion(id, typeId);
      }
    });
  };

  return (
    <Stack direction="row" justifyContent="flex-end">
      <CreateEditQuestion
        typeId={typeId}
        currentQuestion={currentQuestion}
        id={id}
      />
      <Tooltip title="Delete">
        <Button    
          color="error"
          size="small"
          variant="contained"
          onClick={deleteButtonHandler}
          sx={{ ml: 1, maxWidth: 40, maxHeight: 40, minWidth: 40, minHeight: 40}}
          >
            <Icon icon={trash2Outline} width={40} height={40} />
        </Button>
      </Tooltip>
    </Stack>
  );
}
