import PropTypes from "prop-types";
import { filter } from "lodash";
import { useState, useCallback } from "react";
// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TablePagination,
  Box,
} from "@mui/material";
// utils
import { fDateTime } from "../../../../utils/formatTime";
// components
import TableToolbar from "../../../../components/table/TableToolbar";
import Scrollbar from "../../../../components/Scrollbar";
import TableHeader from "../../../../components/table/TableHeader";
import EmployeeTableActions from "./EmployeeTableActions";
import SearchNotFound from "../../../../components/SearchNotFound";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

EmployeeTable.propTypes = {
  list: PropTypes.array,
  tableHead: PropTypes.array,
  companyId: PropTypes.string,
  companyHaveActiveAssessments: PropTypes.bool,
  employeesOptionList: PropTypes.array,
};

export default function EmployeeTable({
  list,
  tableHead,
  companyId,
  companyHaveActiveAssessments,
  employeesOptionList,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("firstName");
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const applySortFilter = useCallback((array, comparator, query) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    if (query) {
      return filter(
        array,
        (item) =>
          (`${item.firstName} ${item.lastName}`
            .toLowerCase()
            .includes(query.trim().toLowerCase())
            || item.companyEmployeeId.toLowerCase()
            .includes(query.trim().toLowerCase()))
      );
    }

    return stabilizedThis.map((el) => el[0]);
  }, []);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredRows = applySortFilter(
    list,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = filteredRows.length === 0;

  return (
    <Card>
      <TableToolbar filterName={filterName} onFilterName={handleFilterByName} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHeader
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={list.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(
                  ({
                    companyEmployeeId,
                    firstName,
                    lastName,
                    assessStatus,
                    lastLogin,
                    email,
                    selfAssessment,
                  }) => {
                    return (
                      <TableRow hover key={companyEmployeeId}>
                        <TableCell component="th" scope="row" padding="none">
                          <Typography variant="subtitle2" noWrap>
                            {`${firstName} ${lastName}`}
                          </Typography>
                          <Typography  sx={{fontSize: '10px'}}>
                            ID:&nbsp;{companyEmployeeId}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {assessStatus ? assessStatus : "N/A"}
                        </TableCell>
                        <TableCell style={{ minWidth: 160 }}>
                          {lastLogin ? fDateTime(lastLogin) : "N/A"}
                        </TableCell>
                        <TableCell>
                          <EmployeeTableActions
                            employee={{
                              companyEmployeeId,
                              firstName,
                              lastName,
                              email,
                              selfAssessment,
                            }}
                            companyId={companyId}
                            companyHaveActiveAssessments={
                              companyHaveActiveAssessments
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <Box sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
