import PropTypes from "prop-types";
import { filter } from "lodash";
import { useState, useCallback } from "react";
// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
} from "@mui/material";
// utils
import { fDateTime } from "../../../../utils/formatTime";
// components
import Scrollbar from "../../../../components/Scrollbar";
import TableHeader from "../../../../components/table/TableHeader";
import Label from "../../../../components/Label";
import AssessmentTableActions from "./AssessmentTableActions";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

AssessmentTable.propTypes = {
  list: PropTypes.array,
  tableHead: PropTypes.array,
  companyId: PropTypes.string,
  companyTitle: PropTypes.string,
};

export default function AssessmentTable({
  list,
  tableHead,
  companyId,
  companyTitle,
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("createdAt");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const applySortFilter = useCallback((array, comparator, query) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    if (query) {
      return filter(
        array,
        (item) =>
          item.companyAssessmentTitle
            .toLowerCase()
            .includes(query.trim().toLowerCase())
      );
    }

    return stabilizedThis.map((el) => el[0]);
  }, []);

  const filteredRows = applySortFilter(list, getComparator(order, orderBy));
  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHeader
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={list.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredRows.map(
                ({
                  companyAssessmentId,
                  companyAssessmentTitle,
                  createdAt,
                  finishedAt,
                  assessmentType,
                  showPreviousScore,
                  statusPercentage,
                }) => {
                  return (
                    <TableRow hover key={companyAssessmentId}>
                      <TableCell component="th" scope="row" padding="none">
                        <Typography variant="subtitle2" noWrap>
                          {companyAssessmentTitle}
                        </Typography>
                          <Typography sx={{fontSize: '10px'}}>
                            ID:&nbsp;{companyAssessmentId}
                          </Typography>
                      </TableCell>
                      <TableCell style={{ minWidth: 160 }}>
                        {fDateTime(createdAt)}
                      </TableCell>
                        {finishedAt ? (
                          <TableCell style={{ minWidth: 160 }}>
                            {fDateTime(finishedAt)}
                          </TableCell>
                        ) : (
                          <TableCell>
                            <Label variant="ghost" color="success">
                              Active
                            </Label>
                          </TableCell>
                        )}
                      <TableCell>{assessmentType}</TableCell>
                      <TableCell>
                        {showPreviousScore ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>{`Finished (${statusPercentage}%)`}</TableCell>
                      <TableCell>
                        <AssessmentTableActions
                          id={companyAssessmentId}
                          isFinished={!!finishedAt}
                          title={companyAssessmentTitle}
                          companyId={companyId}
                          companyTitle={companyTitle}
                          showPreviousScore={showPreviousScore}
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
