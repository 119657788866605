import React, { useCallback, useReducer } from 'react';

import { useSnackbar } from 'notistack';

import useHttp from '../hooks/useHttp';

import { PATH_DASHBOARD } from '../routes/paths';

import { useNavigate } from 'react-router-dom';

const QuestionCategoriesContext = React.createContext({
  list: [],
  error: null,
  isLoading: true,
  fetchQuestionCategories: () => {},
  createQuestionCategory: (title) => {},
  questionCategoryDetails: (id) => {},
  updateQuestionCategory: (questionCategory, id) => {},
  deleteQuestionCategory: (id, title) => {},
});

const questionCategoriesReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALL': {
      return {
        ...state,
        list: action.list,
        isLoading: false,
      };
    }
    case 'UPDATE_QUESTION_CATEGORY': {
      const categoryIndex = state.list.findIndex((cat) => cat.questionCategoryId === action.id);
      const updatedCategory = {
        createdAt: state.list[categoryIndex].createdAt,
        questionCategoryId: state.list[categoryIndex].questionCategoryId,
        questionCategoryTitle: action.questionCategory.questionCategoryTitle,
        updatedAt: new Date().toISOString(),
      };
      const updatedQuestionCategories = [...state.list];
      updatedQuestionCategories[categoryIndex] = updatedCategory;
      return {
        ...state,
        list: updatedQuestionCategories,
      };
    }
    case 'DELETE_QUESTION_CATEGORY': {
      const updatedList = state.list.filter((questionCategory) => action.id !== questionCategory.questionCategoryId);

      return {
        ...state,
        list: updatedList,
      };
    }
    case 'CREATE_QUESTION_CATEGORY': {
      const updatedList = state.list.concat(action.questionCategory);

      return {
        ...state,
        list: updatedList,
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case 'ERROR': {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export const QuestionCategoriesContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { sendRequest: fetchQuestionCategoriesRequest } = useHttp();
  const { sendRequest: deleteQuestionCategoryRequest } = useHttp();

  const { sendRequest: updateQuestionCategoryRequest } = useHttp();

  const { sendRequest: createQuestionCategoryRequest } = useHttp();

  const [questionCategories, dispatch] = useReducer(questionCategoriesReducer, {
    list: [],
    error: null,
    isLoading: true,
  });

  const fetchQuestionCategories = useCallback(() => {
    dispatch({ type: 'SET_LOADING' });
    fetchQuestionCategoriesRequest(
      { url: 'question-categories', method: 'GET' },
      (data) => {
        dispatch({
          type: 'SET_ALL',
          list: data,
        });
      },
      (error) => {
        dispatch({ type: 'ERROR', error });
      },
    );
  }, [fetchQuestionCategoriesRequest]);

  const deleteQuestionCategoryHandler = (id, title) => {
    dispatch({ type: 'DELETE_QUESTION_CATEGORY', id });
    navigate(PATH_DASHBOARD.questionCategories.root);
    deleteQuestionCategoryRequest(
      { url: `question-categories/${id}`, method: 'DELETE' },
      () => {
        enqueueSnackbar(`${title} successfully deleted`, {
          variant: 'success',
        });
      },
      () => {
        fetchQuestionCategories();
      },
    );
  };

  const updateQuestionCategoryHandler = async (questionCategory, id) => {
    await updateQuestionCategoryRequest(
      {
        url: `question-categories/${id}`,
        method: 'PATCH',
        body: questionCategory,
      },
      (data) => {
        enqueueSnackbar(`${data.questionCategoryTitle} updated successfully!`, {
          variant: 'success',
        });
        dispatch({ type: 'UPDATE_QUESTION_CATEGORY', questionCategory, id });
        navigate(PATH_DASHBOARD.questionCategories.root);
      }
    );
  };

  const createQuestionCategoryHandler = async (title) => {
    await createQuestionCategoryRequest(
      {
        url: `question-categories`,
        method: 'POST',
        body: title,
      },
      (data) => {
        dispatch({ type: 'CREATE_QUESTION_CATEGORY', questionCategory: data });
        enqueueSnackbar(`${data.questionCategoryTitle} created successfully!`, {
          variant: 'success',
        });
        navigate(PATH_DASHBOARD.questionCategories.root);
      },
    );
  };

  const questionCategoryDetailsHandler = (id) => {
    const questionCategory = questionCategories.list.find((questionCategory) => questionCategory.questionCategoryId === id);
    return questionCategory;
  };

  const contextValue = {
    list: questionCategories.list,
    error: questionCategories.error,
    isLoading: questionCategories.isLoading,
    fetchQuestionCategories: fetchQuestionCategories,
    questionCategoryDetails: questionCategoryDetailsHandler,
    updateQuestionCategory: updateQuestionCategoryHandler,
    deleteQuestionCategory: deleteQuestionCategoryHandler,
    createQuestionCategory: createQuestionCategoryHandler,
  };

  return <QuestionCategoriesContext.Provider value={contextValue}>{children}</QuestionCategoriesContext.Provider>;
};

export default QuestionCategoriesContext;
