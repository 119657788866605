import * as Yup from "yup";
import PropTypes from "prop-types";
import { Form, FormikProvider, useFormik } from "formik";
// material
import { LoadingButton } from "@mui/lab";
import { Card, Grid, Stack, TextField } from "@mui/material";
// routes
import useQuestionCategories from "../../../hooks/useQuestionCategories";

// ----------------------------------------------------------------------

QuestionCategoryForm.propTypes = {
  isEdit: PropTypes.bool,
  currentQuestionCategory: PropTypes.object,
};

export default function QuestionCategoryForm({
  isEdit,
  currentQuestionCategory,
}) {
  const { createQuestionCategory, updateQuestionCategory } =
    useQuestionCategories();

  const NewQuestionCategorySchema = Yup.object().shape({
    questionCategoryTitle: Yup.string().required("Title is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      questionCategoryTitle:
        currentQuestionCategory?.questionCategoryTitle || "",
    },
    validationSchema: NewQuestionCategorySchema,
    onSubmit: async (values) => {
      if (isEdit) {
        await updateQuestionCategory(
          values,
          currentQuestionCategory.questionCategoryId
        );
      } else {
        await createQuestionCategory(values);
      }
    },
  });

  const {
    errors,
    // values,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Category Title"
                  {...getFieldProps("questionCategoryTitle")}
                  error={Boolean(
                    touched.questionCategoryTitle &&
                      errors.questionCategoryTitle
                  )}
                  helperText={
                    touched.questionCategoryTitle &&
                    errors.questionCategoryTitle
                  }
                />
                <LoadingButton
                  type="submit"
                  sx={{
                    width: 180,
                  }}
                  variant="contained"
                  size="large"
                  loading={isSubmitting}
                >
                  {!isEdit ? "Create Category" : "Save Changes"}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
