import { capitalize } from 'lodash';
// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
// ----------------------------------------------------------------------

function getFirstCharacter(name) {
  return capitalize(name && name.charAt(0));
}

export default function MyAvatar({ ...other }) {
  const { username } = useAuth();

  return (
    <MAvatar
      alt={username}
      color="primary"
      {...other}
    >
      {getFirstCharacter(username)}
    </MAvatar>
  );
}
