import React, { useCallback, useReducer } from 'react';

import { useSnackbar } from 'notistack';

import useHttp from '../hooks/useHttp';

import { PATH_DASHBOARD } from '../routes/paths';

import { useNavigate } from 'react-router-dom';

const CompaniesContext = React.createContext({
  list: [],
  error: null,
  isLoading: true,
  fetchCompanies: () => {},
  createCompany: (title) => {},
  companyDetails: (id) => {},
  updateCompany: (company, id) => {},
  deleteCompany: (id, title) => {},
});

const companiesReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALL': {
      return {
        ...state,
        list: action.list,
        isLoading: false,
      };
    }
    case 'UPDATE_COMPANY': {
      const companyIndex = state.list.findIndex((cat) => cat.companyId === action.id);
      const updatedCompany = {
        createdAt: state.list[companyIndex].createdAt,
        companyId: state.list[companyIndex].companyId,
        companyTitle: action.company.companyTitle,
        updatedAt: new Date().toISOString(),
      };
      const updatedCompanies = [...state.list];
      updatedCompanies[companyIndex] = updatedCompany;
      return {
        ...state,
        list: updatedCompanies,
      };
    }
    case 'DELETE_COMPANY': {
      const updatedList = state.list.filter((company) => action.id !== company.companyId);

      return {
        ...state,
        list: updatedList,
      };
    }
    case 'CREATE_COMPANY': {
      const updatedList = state.list.concat(action.company);

      return {
        ...state,
        list: updatedList,
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case 'ERROR': {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export const CompaniesContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { sendRequest: fetchCompaniesRequest } = useHttp();

  const { sendRequest: deleteCompanyRequest } = useHttp();

  const { sendRequest: updateCompanyRequest } = useHttp();

  const { sendRequest: createCompanyRequest } = useHttp();

  const [companies, dispatch] = useReducer(companiesReducer, {
    list: [],
    error: null,
    isLoading: true,
  });

  const fetchCompanies = useCallback(() => {
    dispatch({ type: 'SET_LOADING' });
    fetchCompaniesRequest(
      { url: 'companies', method: 'GET' },
      (data) => {
        dispatch({
          type: 'SET_ALL',
          list: data,
        });
      },
      (error) => {
        dispatch({ type: 'ERROR', error });
      },
    );
  }, [fetchCompaniesRequest]);

  const deleteCompanyHandler = (id, title) => {
    dispatch({ type: 'DELETE_COMPANY', id });
    navigate(PATH_DASHBOARD.companies.root);
    deleteCompanyRequest(
      { url: `companies/${id}`, method: 'DELETE' },
      () => {
        enqueueSnackbar(`${title} deleted successfully!`, {
          variant: 'success',
        });
      },
      () => {
        fetchCompanies();
      },
    );
  };

  const updateCompanyHandler = async (company, id) => {
    await updateCompanyRequest(
      {
        url: `companies/${id}`,
        method: 'PATCH',
        body: company,
      },
      (data) => {
        enqueueSnackbar(`${data.companyTitle} updated successfully!`, {
          variant: 'success',
        });
        dispatch({ type: 'UPDATE_COMPANY', company, id });
        navigate(PATH_DASHBOARD.companies.root);
      }
    );
  };

  const createCompanyHandler = async (title) => {
    await createCompanyRequest(
      {
        url: `companies`,
        method: 'POST',
        body: title,
      },
      (data) => {
        dispatch({ type: 'CREATE_COMPANY', company: data });
        enqueueSnackbar(`${data.companyTitle} created successfully!`, {
          variant: 'success',
        });
        navigate(`${PATH_DASHBOARD.companies.root}/${data.companyId}/view`);
      },
    );
  };

  const companyDetailsHandler = (id) => {
    const company = companies.list.find((company) => company.companyId === id);
    return company;
  };

  const contextValue = {
    list: companies.list,
    error: companies.error,
    isLoading: companies.isLoading,
    fetchCompanies: fetchCompanies,
    companyDetails: companyDetailsHandler,
    updateCompany: updateCompanyHandler,
    deleteCompany: deleteCompanyHandler,
    createCompany: createCompanyHandler,
  };

  return <CompaniesContext.Provider value={contextValue}>{children}</CompaniesContext.Provider>;
};

export default CompaniesContext;
