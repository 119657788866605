import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import ErrorMsg from "../../../../components/ErrorMsg";
import LoadingScreen from "../../../../components/LoadingScreen";
import CreateEditEmployee from "./CreateEditEmployee";
import EmployeeTable from "./EmployeeTable";
import useEmployees from "../../../../hooks/useEmployees";
import downloadFill from "@iconify/icons-eva/download-fill";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import useHttp from "../../../../hooks/useHttp";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import useAssessments from "../../../../hooks/useAssessments";

Employees.propTypes = {
  companyId: PropTypes.string,
  companyTitle: PropTypes.string,
};

const TABLE_HEAD = [
  { id: "firstName", label: "Name", alignRight: false },
  { id: "assessStatus", label: "Status", alignRight: false },
  { id: "lastLogin", label: "Last Login", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

let isFirstTime = true;

export default function Employees({ companyId, companyTitle }) {
  const { isLoading, fetchEmployees, error, list: employees } = useEmployees();
  const [isDownloadButtonLoading, setIsDownloadButtonLoading] = useState(false);
  const { sendRequest } = useHttp();
  const { companyHaveActiveAssessments } = useAssessments();

  useEffect(() => {
    if (isLoading && isFirstTime) {
      fetchEmployees(companyId);
    }
  }, [fetchEmployees, isLoading, companyId]);

  if (isLoading) {
    return (
      <LoadingScreen
        sx={{
          height: "50vh",
        }}
      />
    );
  }

  if (error) {
    return (
      <ErrorMsg
        msg={error}
        action={() => {
          fetchEmployees(companyId);
        }}
      />
    );
  }

  const downloadHandler = async () => {
    setIsDownloadButtonLoading(true);

    await sendRequest(
      {
        url: `companies/${companyId}/employees/credentials`,
        method: "GET",
      },
      (resData) => {
        const fileType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

        const new_workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(resData);
        XLSX.utils.book_append_sheet(
          new_workbook,
          worksheet,
          `Employees Credentials`
        );
        const excelBuffer = XLSX.write(new_workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${companyTitle} Employees`);
      }
    );

    setIsDownloadButtonLoading(false);
  };

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: 2,
        }}
      >
        <LoadingButton
          color="primary"
          loading={isDownloadButtonLoading}
          variant="contained"
          loadingPosition="start"
          startIcon={<Icon icon={downloadFill} />}
          onClick={downloadHandler}
          sx={{ mr: 1 }}
        >
          Download
        </LoadingButton>
        <CreateEditEmployee
          companyId={companyId}
        />
      </Box>
      {employees.length > 0 && (
        <EmployeeTable
          list={employees}
          companyId={companyId}
          tableHead={TABLE_HEAD}
          companyHaveActiveAssessments={companyHaveActiveAssessments()}
        />
      )}
    </Fragment>
  );
}
