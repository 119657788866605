import * as Yup from "yup";
import PropTypes from "prop-types";
import { Form, FormikProvider, useFormik } from "formik";
// material
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { Card, Grid, Stack, Typography, TextField } from "@mui/material";

import { FormHelperText } from "@material-ui/core";
import { QuillEditor } from "../../../components/editor";
import useEmailTemplates from "../../../hooks/useEmailTemplates";

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

EmailTemplateForm.propTypes = {
  currentEmailTemplate: PropTypes.object,
};

export default function EmailTemplateForm({ currentEmailTemplate }) {
  const { updateEmailTemplate } = useEmailTemplates();

  const NewEmailTemplateSchema = Yup.object().shape({
    emailTemplateTitle: Yup.string().required("Title is required"),
    emailTemplate: Yup.string().required("Template is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      emailTemplateTitle: currentEmailTemplate.emailTemplateTitle,
      emailTemplate: currentEmailTemplate.emailTemplate,
    },
    validationSchema: NewEmailTemplateSchema,
    onSubmit: async (values) => {
     await updateEmailTemplate(values, currentEmailTemplate.emailTemplateId);
    },
  });

  const {
    errors,
    values,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Template Title"
                  {...getFieldProps("emailTemplateTitle")}
                  error={Boolean(
                    touched.emailTemplateTitle && errors.emailTemplateTitle
                  )}
                  helperText={
                    touched.emailTemplateTitle && errors.emailTemplateTitle
                  }
                />
                <div>
                  <LabelStyle>Template</LabelStyle>
                  <QuillEditor
                    simple
                    id="emailTemplate"
                    value={values.emailTemplate}
                    onChange={(val) => setFieldValue("emailTemplate", val)}
                    error={Boolean(
                      touched.emailTemplate && errors.emailTemplate
                    )}
                  />
                  {touched.emailTemplate && errors.emailTemplate && (
                    <FormHelperText error sx={{ px: 2 }}>
                      {touched.emailTemplate && errors.emailTemplate}
                    </FormHelperText>
                  )}
                </div>
                <LoadingButton
                  type="submit"
                  sx={{
                    width: 180,
                  }}
                  variant="contained"
                  size="large"
                  loading={isSubmitting}
                >
                  Save Changes
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
