import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
// material
import { Box, Button, Typography } from "@mui/material";
import { SeverErrorIllustration } from "../assets/illustrations";

const RootStyle = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
}));

ErrorMsg.propTypes = {
  msg: PropTypes.string,
  action: PropTypes.func,
};

export default function ErrorMsg({ msg, action }) {
  return (
    <RootStyle>
      <Box
        sx={{
          maxWidth: 480,
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Typography variant="h3" paragraph>
          {msg}
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          There was an error, please try again later.
        </Typography>

        <SeverErrorIllustration sx={{ height: 260, my: { xs: 3, sm: 5 } }} />

        {action && <Button
          onClick={action}
          sx={{ mt: 3 }}
          size="large"
          variant="contained"
        >
          Try Again
        </Button>}
      </Box>
    </RootStyle>
  );
}
