// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/";

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  companies: {
    root: path(ROOTS_DASHBOARD, "companies"),
    create: path(ROOTS_DASHBOARD, "companies/create"),
  },
  templates: {
    root: path(ROOTS_DASHBOARD, "templates"),
    create: path(ROOTS_DASHBOARD, "templates/create"),
  },
  questionCategories: {
    root: path(ROOTS_DASHBOARD, "question-categories"),
    create: path(ROOTS_DASHBOARD, "question-categories/create"),
  },
  emailTemplates: {
    root: path(ROOTS_DASHBOARD, "email-templates"),
    create: path(ROOTS_DASHBOARD, "email-templates/create"),
  },
};
