// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />;

const ICONS = {
  company: getIcon('ic_company'),
  template: getIcon('ic_template'),
  question: getIcon('ic_question'),
  email: getIcon('ic_email'),
};

const sidebarConfig = [
  {
    items: [
      {
        title: 'Companies',
        path: PATH_DASHBOARD.companies.root,
        icon: ICONS.company,
      },
      {
        title: 'Templates',
        path: PATH_DASHBOARD.templates.root,
        icon: ICONS.template,
      },
      {
        title: 'Question Categories',
        path: PATH_DASHBOARD.questionCategories.root,
        icon: ICONS.question,
      },
      {
        title: 'Email Templates',
        path: PATH_DASHBOARD.emailTemplates.root,
        icon: ICONS.email,
      },
    ],
  },
];

export default sidebarConfig;
