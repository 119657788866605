import * as Yup from "yup";
import { Fragment, useState, forwardRef } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import editFill from "@iconify/icons-eva/edit-fill";
// material
import {
  Slide,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  Typography,
  Tooltip,
  Alert
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { QUESTION_TYPES } from "../../../../constants";

import { FormHelperText } from "@material-ui/core";
import { QuillEditor } from "../../../../components/editor";
import useQuestions from "../../../../hooks/useQuestions";
import useQuestionCategories from "../../../../hooks/useQuestionCategories";

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
}));

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function CreateEditQuestion({
  typeId,
  currentQuestion,
  id
}) {
  const { createQuestion, updateQuestion } =
    useQuestions();
  const { list: questionCategories } = useQuestionCategories();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const NewQuestionSchema = Yup.object().shape({
    questionType: Yup.mixed()
      .oneOf(QUESTION_TYPES, "Type is required")
      .nullable(),
    question: Yup.string().required("Question is required").nullable(),
    questionCategory: Yup.object()
      .required("Question category is required")
      .nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      questionType: currentQuestion
        ? currentQuestion.questionType
        : QUESTION_TYPES[0],
      questionCategory: currentQuestion
        ? currentQuestion.questionCategory
        : questionCategories[0],
      question: currentQuestion ? currentQuestion.question : "",
    },
    validationSchema: NewQuestionSchema,
    onSubmit: async (values, { resetForm }) => {
      setError(null);
      if (currentQuestion) {
        try{
            await updateQuestion(
            {
              ...values,
              questionCategory: {
                questionCategoryId: values.questionCategory.questionCategoryId,
              },
            },
            typeId,
            id,
            values.questionCategory.questionCategoryTitle
          );
          handleClose();
        }
        catch(err){
          setError(err.message);
        }
      } else {
        try{
          await createQuestion(typeId, {
            ...values,
            questionCategory: {
              questionCategoryId: values.questionCategory.questionCategoryId,
            },
          },"simple");
          resetForm();
          handleClose();
        }
        catch(err){
          setError(err.message);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  return (
    <Fragment>
      {currentQuestion ? (
        <Tooltip title="Edit">
          <Button    
          color="primary"
          size="small"
          variant="contained"
          onClick={handleClickOpen}
            sx={{ ml: 1, maxWidth: 40, maxHeight: 40, minWidth: 40, minHeight: 40}}
            >
              <Icon icon={editFill} width={40} height={40} />
          </Button>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<Icon icon={plusFill} />}
        >
          Add New
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <DialogTitle sx={{ mb: 2 }}>Add new question</DialogTitle>
            <DialogContent sx={{ pt: "6px !important" }}>
              <Autocomplete
                value={values.questionType}
                disablePortal
                onChange={(event, newValue) => {
                  setFieldValue("questionType", newValue);
                }}
                options={QUESTION_TYPES.map((option) => option)}
                renderInput={(params) => (
                  <TextField
                    label="Type"
                    {...params}
                    fullWidth
                    {...getFieldProps("questionType")}
                    error={Boolean(touched.questionType && errors.questionType)}
                    helperText={touched.questionType && errors.questionType}
                    sx={{ marginBottom: 2.5 }}
                  />
                )}
              />
              <Autocomplete
                value={values.questionCategory}
                disablePortal
                onChange={(event, newValue) => {
                  setFieldValue("questionCategory", newValue);
                }}
                options={questionCategories}
                getOptionLabel={(option) =>
                  option.questionCategoryTitle || values.questionCategory
                }
                renderInput={(params) => (
                  <TextField
                    label="Category"
                    {...params}
                    fullWidth
                    {...getFieldProps("questionCategory")}
                    error={Boolean(
                      touched.questionCategory && errors.questionCategory
                    )}
                    helperText={
                      touched.questionCategory && errors.questionCategory
                    }
                    sx={{ marginBottom: 2.5 }}
                  />
                )}
              />
              <LabelStyle>Question</LabelStyle>
              <QuillEditor
                simple
                id="question"
                value={values.question}
                onChange={(val) => setFieldValue("question", val)}
                error={Boolean(touched.question && errors.question)}
              />
              {touched.question && errors.question && (
                <FormHelperText error sx={{ px: 2 }}>
                  {touched.question && errors.question}
                </FormHelperText>
              )}
              {error && <Alert severity="error" sx={{mt: 2}}>{error}</Alert>}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="inherit">
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {currentQuestion ? "Save Changes" : "Create"}
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </Fragment>
  );
}
