import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";
import editFill from "@iconify/icons-eva/edit-fill";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
// material
import { Button, Container } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import useQuestionCategories from "../../hooks/useQuestionCategories";

import Table from "../../components/table";
import LoadingScreen from "../../components/LoadingScreen";
import ErrorMsg from "../../components/ErrorMsg";
import NoData from "../../components/NoData";
import { useEffect } from "react";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "questionCategoryTitle", label: "Title", alignRight: false },
  { id: "createdAt", label: "Created at", alignRight: false },
  { id: "updatedAt", label: "Updated at", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

// ----------------------------------------------------------------------
let isFirstTime = true;
export default function QuestionCategories() {
  const {
    list: questionCategories,
    deleteQuestionCategory,
    fetchQuestionCategories,
    isLoading,
    error,
  } = useQuestionCategories();

  useEffect(() => {
    if (isLoading && isFirstTime) {
      fetchQuestionCategories();
    }
  }, [fetchQuestionCategories, isLoading]);

  if (isLoading) {
    return (
      <LoadingScreen
        sx={{
          height: "80vh",
        }}
      />
    );
  }

  if (error) {
    return <ErrorMsg msg={error} action={fetchQuestionCategories} />;
  }

  if (questionCategories.length === 0) {
    return (
      <NoData
        msg="No Question Categories Found!"
        url={PATH_DASHBOARD.questionCategories.create}
      />
    );
  }

  const TABLE_ACTIONS = [
    {
      type: "url",
      label: "Edit",
      urlType: "edit",
      color: "primary",
      icon: editFill,
    },
    {
      type: "button",
      label: "Delete",
      color: "error",
      clickHandler: deleteQuestionCategory,
      icon: trash2Outline,
    },
  ];

  return (
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Question Categories"
        links={[
          { name: "Home", href: PATH_DASHBOARD.root },
          { name: "Question Categories" },
        ]}
        action={
          <Button
            variant="contained"
            component={RouterLink}
            to={PATH_DASHBOARD.questionCategories.create}
            startIcon={<Icon icon={plusFill} />}
          >
            Add New
          </Button>
        }
      />
      <Table
        list={questionCategories}
        tableHead={TABLE_HEAD}
        tableActions={TABLE_ACTIONS}
        name="questionCategories"
        title="questionCategoryTitle"
      />
    </Container>
  );
}
