import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import ErrorMsg from "../../../components/ErrorMsg";
import LoadingScreen from "../../../components/LoadingScreen";
import useQuestions from "../../../hooks/useQuestions";
import useQuestionCategories from "../../../hooks/useQuestionCategories";
import CreateEditQuestion from "../../Companies/View/Questions/CreateEditQuestion";
import QuestionsTable from "../../Companies/View/Questions/QuestionsTable";
// components
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";

import { Box, Container } from "@mui/material";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { useParams } from "react-router";
import useTemplates from "../../../hooks/useTemplates";

TemplateView.propTypes = {
  companyId: PropTypes.string,
};

const TABLE_HEAD = [
  { id: "questionCategoryTitle", label: "Category", alignRight: false },
  { id: "question", label: "Question", alignRight: false },
  { id: "questionType", label: "Type", alignRight: false },
  { id: "createdAt", label: "Created at", alignRight: false },
  { id: "updatedAt", label: "Updated at", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

export default function TemplateView() {
  const { id } = useParams();
  const [isFirstTime, setIsFirstTime] = useState(true);
  const {
    fetchQuestions,
    isLoading,
    error,
    list: templateQuestions,
  } = useQuestions();
  const {
    isLoading: isQuestionCategoriesLoading,
    fetchQuestionCategories,
    error: questionCategoriesError,
  } = useQuestionCategories();
  const {
    templateDetails,
    fetchTemplates,
    isLoading: templatesLoading,
    error: templatesError,
  } = useTemplates();

  useEffect(() => {
    if (isQuestionCategoriesLoading && isFirstTime) {
      fetchQuestionCategories();
    }
    if (isLoading && isFirstTime) {
      fetchQuestions(id, "templates");
    }
    if (templatesLoading && isFirstTime) {
      fetchTemplates();
    }
    setIsFirstTime(false);
   }, [
    fetchQuestions,
    isLoading,
    id,
    isQuestionCategoriesLoading,
    fetchQuestionCategories,
    templatesLoading,
    fetchTemplates,
    isFirstTime
  ]);

  if (isLoading || isQuestionCategoriesLoading || templatesLoading) {
    return (
      <LoadingScreen
        sx={{
          height: "80vh",
        }}
      />
    );
  }

  if (error || questionCategoriesError || templatesError) {
    return (
      <ErrorMsg
        msg={error}
        action={() => {
          fetchQuestionCategories();
          fetchQuestions(id, "templates");
          fetchTemplates();
        }}
      />
    );
  }

  const template = templateDetails(id);

  const templateQuestionsList = templateQuestions.map((templateQuestion) => ({
    ...templateQuestion,
    questionCategoryTitle: templateQuestion.questionCategory.questionCategoryTitle
  })); 

  return (
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Template Details"
        links={[
          { name: "Home", href: PATH_DASHBOARD.root },
          { name: "Templates", href: PATH_DASHBOARD.templates.root },
          { name: template.templateTitle },
        ]}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: 2,
        }}
      >
        <CreateEditQuestion typeId={id} />
      </Box>
      <QuestionsTable
        list={templateQuestionsList}
        tableHead={TABLE_HEAD}
        typeId={id}
      />
    </Container>
  );
}
