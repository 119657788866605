import * as Yup from 'yup';
import { Fragment, useState, forwardRef, useCallback } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Icon } from '@iconify/react';
import emailFill from '@iconify/icons-eva/email-fill';
import { useSnackbar } from 'notistack';
// material
import {
  Slide,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  Typography,
  Checkbox,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { FormHelperText } from '@material-ui/core';
//
import { QuillEditor } from '../../../../components/editor';
import useEmailTemplates from '../../../../hooks/useEmailTemplates';

import { EMAIL_METHODS } from '../../../../constants';
import useHttp from '../../../../hooks/useHttp';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import ErrorMsg from '../../../../components/ErrorMsg';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
}));

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function EmailAssessment({ companyId, companyTitle, companyAssessmentId }) {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [assessors, setAssessors] = useState([]);
  const [error, setError] = useState(null);
  const { list: templates } = useEmailTemplates();
  const { sendRequest } = useHttp();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleClickOpen = () => {
    setOpen(true);
    sendRequest({ url: `companies/${companyId}/employees/assessors`, method: 'GET' }, (data) => {
      setAssessors(data);
    },(error) => {setError(error)});
    fetchTemplate(templates[0].emailTemplateId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const NewAssessmentSchema = Yup.object().shape({
    method: Yup.mixed().oneOf(EMAIL_METHODS, 'Method is required'),
    template: Yup.object().required('Template is required').nullable(),
    subject: Yup.string().required('Subject is required'),
    message: Yup.string().required('Message is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      method: EMAIL_METHODS[0],
      template: templates[0],
      subject: '',
      message: '',
      companyEmployees: [],
    },
    validationSchema: NewAssessmentSchema,
    onSubmit: async (values, { resetForm }) => {
      const body = {
        method: values.method,
        subject: values.subject,
        message: values.message,
        companyEmployeeIds: values.companyEmployees.map((employee) => employee.companyEmployeeId),
      };
      if (values.method === 'Export') {
        setIsLoading(true);
        sendRequest(
          {
            url: `companies/${companyId}/assessments/${companyAssessmentId}/email`,
            method: 'POST',
            body,
          },
          (data) => {
            navigate('/email/export', {
              state: { list: data, companyId, companyTitle },
            });

            setIsLoading(false);
            handleClose();
          },
          () => {
            setIsLoading(false);
          },
        );
      } else {
        swal({
          title: 'Are you sure?',
          text: `You want to send the email to ${
            values.companyEmployees.length === 0 ? 'all the' : values.companyEmployees.length
          } assessors?`,
          icon: 'warning',
          dangerMode: true,
          buttons: {
            cancel: true,
            confirm: { text: 'Send', className: 'bg-info' },
          },
        }).then((isSend) => {
          if (isSend) {
            setIsLoading(true);
            sendRequest(
              {
                url: `companies/${companyId}/assessments/${companyAssessmentId}/email`,
                method: 'POST',
                body,
                type: 'email',
              },
              () => {
                enqueueSnackbar('Email sent!', {
                  variant: 'success',
                });
                setIsLoading(false);
                resetForm();
                handleClose();
              },
              () => {
                setIsLoading(false);
              },
            );
          }
        });
      }
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, values, setFieldValue } = formik;

  const fetchTemplate = useCallback(
    async (id) => {
      sendRequest({ url: `email-templates/${id}`, method: 'GET' }, (data) => {
        setFieldValue('message', data.emailTemplate);
      });
    },
    [sendRequest, setFieldValue],
  );

  return (
    <Fragment>
      <Tooltip title="Email">
        <Button
          color="info"
          size="small"
          variant="contained"
          onClick={handleClickOpen}
          sx={{ ml: 1, maxWidth: 40, maxHeight: 40, minWidth: 40, minHeight: 40 }}
        >
          <Icon icon={emailFill} width={40} height={40} />
        </Button>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} maxWidth="md" fullWidth>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <DialogTitle sx={{ mb: 2 }}>Email assessment request</DialogTitle>
            {error ? <ErrorMsg msg={error}/> : 
              <Fragment>
                <DialogContent sx={{ pt: '6px !important' }}>
                  <Autocomplete
                    fullWidth
                    disablePortal
                    value={values.method}
                    onChange={(event, newValue) => {
                      setFieldValue('method', newValue);
                    }}
                    options={EMAIL_METHODS}
                    renderInput={(params) => (
                      <TextField
                        label="Method"
                        {...params}
                        fullWidth
                        error={Boolean(touched.method && errors.method)}
                        helperText={touched.method && errors.method}
                        sx={{ marginBottom: 2.5 }}
                      />
                    )}
                  />
                  <Autocomplete
                    fullWidth
                    disablePortal
                    value={values.template}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setFieldValue('template', newValue);
                        fetchTemplate(newValue.emailTemplateId);
                      } else {
                        setFieldValue('template', values.template);
                      }
                    }}
                    options={templates}
                    getOptionLabel={(option) => option.emailTemplateTitle || values.template}
                    renderInput={(params) => (
                      <TextField
                        label="Template"
                        {...params}
                        fullWidth
                        error={Boolean(touched.template && errors.template)}
                        helperText={touched.template && errors.template}
                        sx={{ marginBottom: 2.5 }}
                      />
                    )}
                  />
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    value={values.companyEmployees}
                    onChange={(event, newValue) => {
                      setFieldValue('companyEmployees', newValue);
                    }}
                    options={assessors.map((option) => option)}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox checked={selected} />
                        {`${option.firstName} ${option.lastName}`}
                      </li>
                    )}
                    renderInput={(params) => <TextField label="Email To:" {...params} sx={{ marginBottom: 2.5 }} />}
                  />
                  <TextField
                    fullWidth
                    label="Subject"
                    {...getFieldProps('subject')}
                    error={Boolean(touched.subject && errors.subject)}
                    helperText={touched.subject && errors.subject}
                    sx={{ marginBottom: 2.5 }}
                  />
                  <div>
                    <LabelStyle>Message</LabelStyle>
                    <QuillEditor
                      simple
                      id="message"
                      value={values.message}
                      onChange={(val) => setFieldValue('message', val)}
                      error={Boolean(touched.message && errors.message)}
                    />
                    {touched.message && errors.message && (
                      <FormHelperText error sx={{ px: 2 }}>
                        {touched.message && errors.message}
                      </FormHelperText>
                    )}
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="inherit">
                    Cancel
                  </Button>
                  <LoadingButton type="submit" variant="contained" loading={isLoading}>
                    {values.method === 'Export' ? 'Export' : 'Send Email'}
                  </LoadingButton>
                </DialogActions>
              </Fragment>
            }
          </Form>
        </FormikProvider>
      </Dialog>
    </Fragment>
  );
}
