import React, { useCallback, useReducer } from 'react';

import { useSnackbar } from 'notistack';

import useHttp from '../hooks/useHttp';

import { PATH_DASHBOARD } from '../routes/paths';

import { useNavigate } from 'react-router-dom';

const EmailTemplatesContext = React.createContext({
  list: [],
  error: null,
  isLoading: true,
  fetchEmailTemplates: () => {},
  createEmailTemplate: (emailTemplate) => {},
  updateEmailTemplate: (emailTemplate, id) => {},
  deleteEmailTemplate: (id, title) => {},
});

const emailTemplatesReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALL': {
      return {
        ...state,
        list: action.list,
        isLoading: false,
      };
    }
    case 'UPDATE_EMAIL_TEMPLATE': {
      const templateIndex = state.list.findIndex((template) => template.emailTemplateId === action.id);
      const updatedTemplate = {
        createdAt: state.list[templateIndex].createdAt,
        emailTemplateId: state.list[templateIndex].emailTemplateId,
        emailTemplateTitle: action.emailTemplate.emailTemplateTitle,
        updatedAt: new Date().toISOString(),
      };
      const updatedEmailTemplates = [...state.list];
      updatedEmailTemplates[templateIndex] = updatedTemplate;
      return {
        ...state,
        list: updatedEmailTemplates,
      };
    }
    case 'DELETE_EMAIL_TEMPLATE': {
      const updatedList = state.list.filter((emailTemplate) => action.id !== emailTemplate.emailTemplateId);

      return {
        ...state,
        list: updatedList,
      };
    }
    case 'CREATE_EMAIL_TEMPLATE': {
      delete action.emailTemplate.emailTemplate;
      const updatedList = state.list.concat(action.emailTemplate);
      return {
        ...state,
        list: updatedList,
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case 'ERROR': {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export const EmailTemplatesContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { sendRequest: fetchEmailTemplatesRequest } = useHttp();

  const { sendRequest: deleteEmailTemplateRequest } = useHttp();

  const { sendRequest: updateEmailTemplateRequest } = useHttp();

  const { sendRequest: createEmailTemplateRequest } = useHttp();

  const [emailTemplates, dispatch] = useReducer(emailTemplatesReducer, {
    list: [],
    error: null,
    isLoading: true,
  });

  const fetchEmailTemplates = useCallback(() => {
    dispatch({ type: 'SET_LOADING' });
    fetchEmailTemplatesRequest(
      { url: 'email-templates', method: 'GET' },
      (data) => {
        dispatch({
          type: 'SET_ALL',
          list: data,
        });
      },
      (error) => {
        dispatch({ type: 'ERROR', error });
      },
    );
  }, [fetchEmailTemplatesRequest]);

  const deleteEmailTemplateHandler = (id, title) => {
    dispatch({ type: 'DELETE_EMAIL_TEMPLATE', id });
    navigate(PATH_DASHBOARD.emailTemplates.root);
    deleteEmailTemplateRequest(
      { url: `email-templates/${id}`, method: 'DELETE' },
      () => {
        enqueueSnackbar(`${title} deleted successfully!`, {
          variant: 'success',
        });
      },
      () => {
        fetchEmailTemplates();
      },
    );
  };

  const updateEmailTemplateHandler = async (emailTemplate, id) => {
    await updateEmailTemplateRequest(
      {
        url: `email-templates/${id}`,
        method: 'PATCH',
        body: emailTemplate,
      },
      (data) => {
        enqueueSnackbar(`${data.emailTemplateTitle} updated successfully!`, {
          variant: 'success',
        });
        dispatch({ type: 'UPDATE_EMAIL_TEMPLATE', emailTemplate, id });
        navigate(PATH_DASHBOARD.emailTemplates.root);
      }
    );
  };

  const createEmailTemplateHandler = async (title) => {
    await createEmailTemplateRequest(
      {
        url: `email-templates`,
        method: 'POST',
        body: title,
      },
      (data) => {
        dispatch({ type: 'CREATE_EMAIL_TEMPLATE', emailTemplate: data });
        enqueueSnackbar(`${data.emailTemplateTitle} created successfully!`, {
          variant: 'success',
        });
        navigate(PATH_DASHBOARD.emailTemplates.root);
      },
    );
  };

  const contextValue = {
    list: emailTemplates.list,
    error: emailTemplates.error,
    isLoading: emailTemplates.isLoading,
    fetchEmailTemplates: fetchEmailTemplates,
    updateEmailTemplate: updateEmailTemplateHandler,
    deleteEmailTemplate: deleteEmailTemplateHandler,
    createEmailTemplate: createEmailTemplateHandler,
  };

  return <EmailTemplatesContext.Provider value={contextValue}>{children}</EmailTemplatesContext.Provider>;
};

export default EmailTemplatesContext;
