import * as Yup from "yup";
import { Fragment, useState, forwardRef, useCallback } from "react";
import { Form, FormikProvider, useFormik, FieldArray, getIn } from "formik";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import editFill from "@iconify/icons-eva/edit-fill";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
// material
import {
  Slide,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch,
  Autocomplete,
  Stack,
  Alert,
} from "@mui/material";
//
import { COLLEAGUE_TYPES } from "../../../../constants";
import { LoadingButton } from "@mui/lab";
import useEmployees from "../../../../hooks/useEmployees";
import useHttp from "../../../../hooks/useHttp";
import LoadingScreen from "../../../../components/LoadingScreen";
import ErrorMsg from "../../../../components/ErrorMsg";

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function CreateEditEmployee({
  companyId,
  currentEmployee
}) {
  const [open, setOpen] = useState(false);
  const [employeesOptionList, setEmployeesOptionList] = useState([]);
  const [selfAssessment, setSelfAssessment] = useState(false);
  const { list, createEmployee, updateEmployee } = useEmployees();
  const [isLoading, setIsLoading] =  useState(true);
  const { sendRequest,  } = useHttp();
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const NewEmployeeSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().required("Email is required").email(),
    colleagues: Yup.array().of(
      Yup.object().shape({
        colleagueType: Yup.mixed().oneOf(
          COLLEAGUE_TYPES,
          "Colleague type is required"
        ),
        employee: Yup.object().required("Employee is required").nullable(),
      })
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: currentEmployee ? currentEmployee.firstName : "",
      lastName: currentEmployee ? currentEmployee.lastName : "",
      selfAssessment: selfAssessment,
      email: currentEmployee ? currentEmployee.email : "",
      colleagues: []
    },
    validationSchema: NewEmployeeSchema,
    onSubmit: async (values, { resetForm }) => {
      const body = {
        ...values,
        colleagues: values.colleagues.map((colleague) => {
          return {
            companyEmployeeId: colleague.employee.companyEmployeeId,
            colleagueType: colleague.colleagueType,
          };
        }),
      };
      setFormError(null);
      if (currentEmployee) {
        try{
          await updateEmployee(
            companyId,
            body,
            currentEmployee.companyEmployeeId
          );
          handleClose();
        }
        catch(err){
          setFormError(err.message);
        }
      } else {
        try{
          await createEmployee(companyId, body);
          resetForm();
          handleClose();
        }
        catch(err){
          setFormError(err.message);
        }
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  const handleClickOpen = () => {
    setOpen(true);
    if(currentEmployee){
      fetchColleagues();
    }
    else{
      const employeesOptions = list.map((employee) => {
        return {
          companyEmployeeId: employee.companyEmployeeId,
          companyEmployeeName: `${employee.firstName} ${employee.lastName}`,
        };
      });

      const colleagues = values.colleagues.filter((colleague) => colleague.employee);

      const updatedEmployeesOptionList = [].concat(
        colleagues.filter((obj1) =>
          employeesOptions.every(
            (obj2) => obj1.employee.companyEmployeeId !== obj2.companyEmployeeId
          )
        ),
        employeesOptions.filter((obj2) =>
          colleagues.every(
            (obj1) => obj2.companyEmployeeId !== obj1.employee.companyEmployeeId
          )
        )
      );
      setEmployeesOptionList(updatedEmployeesOptionList);
    }
  };

  const fetchColleagues = useCallback(async () => {
    let employeeCollegues = [];
    setIsLoading(true);
    setError(null);
    await sendRequest(
      {
        url: `companies/${companyId}/employees/${currentEmployee.companyEmployeeId}`,
        method: "GET"
      },
      (employee) => {
        setSelfAssessment(employee.selfAssessment);

        employeeCollegues = employee.colleagues.map((employee) => {
          return {
            colleagueType: employee.colleagueType,
            employee: {
              companyEmployeeId: employee.companyEmployeeId,
              companyEmployeeName: `${employee.firstName} ${employee.lastName}`,
            },
          };
        });

        const employeesOptions = list.map((emp) => {
            return {
              companyEmployeeId: emp.companyEmployeeId,
              companyEmployeeName: `${emp.firstName} ${emp.lastName}`,
            };
        });
        
        // removing current employee from list
        const filteredEmployeesOptions = employeesOptions.filter(
          (emp) => emp.companyEmployeeId !== employee.companyEmployeeId
        );
      
        const updatedEmployeesOptionList = [].concat(
          employeeCollegues.filter((obj1) =>
            filteredEmployeesOptions.every(
              (obj2) => obj1.employee.companyEmployeeId !== obj2.companyEmployeeId
            )
          ),
          filteredEmployeesOptions.filter((obj2) =>
            employeeCollegues.every(
              (obj1) => obj2.companyEmployeeId !== obj1.employee.companyEmployeeId
            )
          )
        );
        setEmployeesOptionList(updatedEmployeesOptionList);
      },
      (error) => {
        setError(error);
      }
    );
    setIsLoading(false);
    setFieldValue('colleagues', employeeCollegues);
  },[currentEmployee, sendRequest, companyId,setEmployeesOptionList,setFieldValue,list]);


  return (
    <Fragment>
      {currentEmployee ? (
        <Button
          color="primary"
          size="small"
          variant="contained"
          startIcon={<Icon icon={editFill} />}
          onClick={handleClickOpen}
          sx={{ ml: 1 }}
        >
          Edit
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<Icon icon={plusFill} />}
        >
          Add New
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <DialogTitle sx={{ mb: 2 }}>
              {currentEmployee ? "Edit Employee" : "Add New Employee"}
            </DialogTitle>
            <DialogContent sx={{ pt: "6px !important" }}>
            {currentEmployee && isLoading && !error && (
              <LoadingScreen
                sx={{
                  height: "16vh",
                }}
              />
            )}
            {currentEmployee && !isLoading && error && (
              <ErrorMsg
                msg={error}
                action={() => {
                  fetchColleagues();
                }}
              />
            )}
            {((!isLoading && !error) || !currentEmployee) && (
              <Fragment>
                <TextField
                  fullWidth
                  label="First Name"
                  {...getFieldProps("firstName")}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                  sx={{ marginBottom: 2.5 }}
                />
                <TextField
                  fullWidth
                  label="Last Name"
                  {...getFieldProps("lastName")}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  sx={{ marginBottom: 2.5 }}
                />
                <TextField
                  fullWidth
                  label="Email"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ marginBottom: 2.5 }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      {...getFieldProps("selfAssessment")}
                      checked={values.selfAssessment}
                    />
                  }
                  label="Self Assessment"
                />
                {list.length > 0 && (
                  <FieldArray name="colleagues">
                    {({ push, remove }) => (
                      <div>
                        {values.colleagues.map((c, index) => {
                          const employee = `colleagues[${index}].employee`;
                          const touchedEmployee = getIn(touched, employee);
                          const errorEmployee = getIn(errors, employee);

                          const colleagueType = `colleagues[${index}].colleagueType`;
                          const touchedColleagueType = getIn(
                            touched,
                            colleagueType
                          );
                          const errorColleagueType = getIn(errors, colleagueType);

                          return (
                            <Stack key={index} direction="row" sx={{ my: 2 }}>
                              <Autocomplete
                                disablePortal
                                value={c.colleagueType}
                                onChange={(event, newValue) => {
                                  setFieldValue(colleagueType, newValue);
                                }}
                                options={COLLEAGUE_TYPES}
                                name={colleagueType}
                                required
                                renderInput={(params) => (
                                  <TextField
                                    label="Colleague Type"
                                    {...params}
                                    error={Boolean(
                                      touchedColleagueType && errorColleagueType
                                    )}
                                    helperText={
                                      touchedColleagueType && errorColleagueType
                                    }
                                    sx={{ width: 300, marginRight: 2 }}
                                  />
                                )}
                              />
                              <Autocomplete
                                disablePortal
                                value={c.employee}
                                onChange={(event, newValue) => {
                                  if (newValue) {
                                    setEmployeesOptionList((prevEmployeesOptionList) => {
                                      return prevEmployeesOptionList.filter(
                                        (emp) => emp.companyEmployeeId !== newValue.companyEmployeeId
                                      );
                                    });
                                    if (c.employee.companyEmployeeId) {
                                      setEmployeesOptionList((prevEmployeesOptionList) => {
                                        return prevEmployeesOptionList.concat(c.employee);
                                      });
                                    }
                                    setFieldValue(employee, newValue);
                                  }
                                }}
                                options={employeesOptionList}
                                name={employee}
                                required
                                getOptionLabel={(option) =>
                                  option.companyEmployeeName || c.employee
                                }
                                renderInput={(params) => (
                                  <TextField
                                    label="Employee"
                                    {...params}
                                    error={Boolean(
                                      touchedEmployee && errorEmployee
                                    )}
                                    helperText={touchedEmployee && errorEmployee}
                                    sx={{ width: 300, marginRight: 2 }}
                                  />
                                )}
                              />

                              <Button
                                color="error"
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                  if (c.employee.companyEmployeeId) {
                                    setEmployeesOptionList((prevEmployeesOptionList) => {
                                      return prevEmployeesOptionList.concat(c.employee);
                                    });
                                  }
                                  remove(index);
                                }}
                                startIcon={<Icon icon={trash2Outline} />}
                                sx={{ maxHeight: 56 }}
                              >
                                Remove
                              </Button>
                            </Stack>
                          );
                        })}
                        <Button
                          type="button"
                          variant="outlined"
                          onClick={() =>
                            push({
                              colleagueType: COLLEAGUE_TYPES[0],
                              employee: "",
                            })
                          }
                          sx={{ mt: 1 }}
                        >
                          Add Colleague
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                )}
              </Fragment>
            )}
            {formError && <Alert severity="error" sx={{mt: 2}}>{formError}</Alert>}
            </DialogContent>
            {(!currentEmployee || (!isLoading && !error)) && (
              <DialogActions>
                <Button onClick={handleClose} color="inherit">
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {currentEmployee ? "Save Changes" : "Create"}
                </LoadingButton>
              </DialogActions>
            )}
          </Form>
        </FormikProvider>
      </Dialog>
    </Fragment>
  );
}
