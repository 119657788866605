import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { useSnackbar } from 'notistack';

import useHttp from '../hooks/useHttp';

const AssessmentsContext = React.createContext({
  list: [],
  error: null,
  isLoading: true,
  types: [],
  fetchAssessments: (companyId) => {},
  createAssessment: (companyId, assessment) => {},
  updateAssessment: (companyId, assessment, id) => {},
  deleteAssessment: (id, title) => {},
  fetchTypes: () => {},
  markAsFinished: (id) => {},
  resetAssessment: (id, status) => {},
  companyHaveActiveAssessments: () => {},
});

const assessmentsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALL': {
      return {
        ...state,
        companyId: action.companyId,
        list: action.list,
        isLoading: false,
      };
    }
    case 'UPDATE_ASSESSMENT': {
      const assessmentIndex = state.list.findIndex((assessment) => assessment.companyAssessmentId === action.id);

      const updatedAssessment = {
        ...state.list[assessmentIndex],
        companyAssessmentTitle: action.assessment.companyAssessmentTitle,
        showPreviousScore: action.assessment.showPreviousScore,
      };
      const updatedAssessments = [...state.list];
      updatedAssessments[assessmentIndex] = updatedAssessment;

      return {
        ...state,
        list: updatedAssessments,
      };
    }
    case 'FINISH_ASSESSMENT': {
      const assessmentIndex = state.list.findIndex((assessment) => assessment.companyAssessmentId === action.id);

      const finishedAssessment = {
        ...state.list[assessmentIndex],
        finishedAt: action.datetime,
      };
      const finishedAssessments = [...state.list];
      finishedAssessments[assessmentIndex] = finishedAssessment;

      return {
        ...state,
        list: finishedAssessments,
      };
    }
    case 'DELETE_ASSESSMENT': {
      const updatedList = state.list.filter((assessment) => action.id !== assessment.companyAssessmentId);

      return {
        ...state,
        list: updatedList,
      };
    }
    case 'CREATE_ASSESSMENT': {
      const updatedList = state.list.concat(action.assessment);

      return {
        ...state,
        list: updatedList,
      };
    }
    case 'RESET_ASSESSMENT': {
      const assessmentIndex = state.list.findIndex((assessment) => assessment.companyAssessmentId === action.id);

      const updatedAssessment = {
        ...state.list[assessmentIndex],
        statusPercentage: action.status,
      };
      const updatedAssessments = [...state.list];
      updatedAssessments[assessmentIndex] = updatedAssessment;

      return {
        ...state,
        list: updatedAssessments,
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case 'ERROR': {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export const AssessmentsContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [types, setTypes] = useState([]);

  const { sendRequest: fetchAssessmentsRequest } = useHttp();

  const { sendRequest: deleteAssessmentRequest } = useHttp();

  const { sendRequest: updateAssessmentRequest } = useHttp();

  const { sendRequest: createAssessmentRequest } = useHttp();

  const { sendRequest: fetchTypesRequest } = useHttp();

  const { sendRequest: markFinishedRequest } = useHttp();

  const [assessments, dispatch] = useReducer(assessmentsReducer, {
    list: [],
    companyId: '',
    error: null,
    isLoading: true,
  });

  const fetchAssessments = useCallback(
    (companyId) => {
      dispatch({ type: 'SET_LOADING' });
      fetchAssessmentsRequest(
        { url: `companies/${companyId}/assessments`, method: 'GET' },
        (data) => {
          dispatch({
            type: 'SET_ALL',
            list: data,
            companyId,
          });
        },
        (error) => {
          dispatch({ type: 'ERROR', error });
        },
      );
    },
    [fetchAssessmentsRequest],
  );

  const fetchTypesHandler = useCallback(() => {
    dispatch({ type: 'SET_LOADING' });
    fetchTypesRequest(
      { url: 'assessments/types', method: 'GET' },
      (data) => {
        setTypes(data);
      },
      (error) => {
        dispatch({ type: 'ERROR', error });
      },
    );
  }, [fetchTypesRequest]);

  useEffect(() => {
    fetchTypesHandler();
  }, [fetchTypesHandler]);

  const deleteAssessmentHandler = (id, title) => {
    dispatch({ type: 'DELETE_ASSESSMENT', id });
    deleteAssessmentRequest(
      { url: `assessments/${id}`, method: 'DELETE' },
      () => {
        enqueueSnackbar(`${title} deleted successfully!`, {
          variant: 'success',
        });
      },
      () => {
        fetchAssessments(assessments.companyId);
      },
    );
  };

  const updateAssessmentHandler = async(companyId, assessment, id) => {
    await updateAssessmentRequest(
      {
        url: `companies/${companyId}/assessments/${id}`,
        method: 'PATCH',
        body: assessment,
      },
      (data) => {
        enqueueSnackbar(`${data.companyAssessmentTitle} updated successfully!`, {
          variant: 'success',
        });
        dispatch({ type: 'UPDATE_ASSESSMENT', assessment, id });
      },
      (error) => {
        throw new Error(error);
      }
    );
  };

  const createAssessmentHandler = async (companyId, assessment) => {
    await createAssessmentRequest(
      {
        url: `companies/${companyId}/assessments`,
        method: 'POST',
        body: assessment,
      },
      (data) => {
        dispatch({ type: 'CREATE_ASSESSMENT', assessment: data });
        enqueueSnackbar(`${data.companyAssessmentTitle} created successfully!`, {
          variant: 'success',
        });
      },
      (error) => {
        throw new Error(error);
      }
    );
  };

  const markAsFinishedHandler = (companyId, id) => {
    const datetime = new Date().toISOString();
    dispatch({ type: 'FINISH_ASSESSMENT', id, datetime });
    markFinishedRequest(
      {
        url: `companies/${companyId}/assessments/${id}`,
        method: 'PATCH',
        body: { finishedAt: datetime },
      },
      (data) => {
        enqueueSnackbar(`${data.companyAssessmentTitle} marked finished successfully!`, {
          variant: 'success',
        });
      },
      () => {
        fetchAssessments(assessments.companyId);
      },
    );
  };

  const resetAssessmentHandler = (id, status) => {
    dispatch({ type: 'RESET_ASSESSMENT', id, status });
  };

  const companyHaveActiveAssessmentsHandler = () => {
    const activeAssessment = assessments.list.find((assessment) => !assessment.finishedAt);
    return activeAssessment ? true : false;
  };

  const contextValue = {
    list: assessments.list,
    error: assessments.error,
    isLoading: assessments.isLoading,
    fetchAssessments: fetchAssessments,
    updateAssessment: updateAssessmentHandler,
    deleteAssessment: deleteAssessmentHandler,
    createAssessment: createAssessmentHandler,
    fetchTypes: fetchTypesHandler,
    types: types,
    markAsFinished: markAsFinishedHandler,
    resetAssessment: resetAssessmentHandler,
    companyHaveActiveAssessments: companyHaveActiveAssessmentsHandler,
  };

  return <AssessmentsContext.Provider value={contextValue}>{children}</AssessmentsContext.Provider>;
};

export default AssessmentsContext;
