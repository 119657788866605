import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import personFill from "@iconify/icons-eva/person-fill";
import lockOutline from "@iconify/icons-eva/lock-outline";

import { Link as RouterLink } from "react-router-dom";
// material
import { alpha } from "@mui/material/styles";
import { Button, Box, Divider, MenuItem, Typography } from "@mui/material";
// hooks
import useAuth from "../../hooks/useAuth";
// components
import { MIconButton } from "../../components/@material-extend";
import MyAvatar from "../../components/MyAvatar";
import MenuPopover from "../../components/MenuPopover";
import { useTheme } from "@mui/material/styles";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "My Profile",
    icon: personFill,
    linkTo: "/profile",
  },
  {
    label: "Change Password",
    icon: lockOutline,
    linkTo: "/change-password",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { username, role, logout } = useAuth();
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography
            variant="subtitle1"
            noWrap
            sx={{ color: theme.palette.text.primary }}
          >
            {username}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {role}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{
              typography: "body2",
              py: 1,
              px: 2.5,
              color: theme.palette.text.primary,
            }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={logout}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
