// material
import { Container } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import EmailTemplateForm from "./EmailTemplateForm";

// ----------------------------------------------------------------------

export default function EmailTemplateCreate() {
  return (
    <Container maxWidth={false} >
      <HeaderBreadcrumbs
        heading="Create a new email template"
        links={[
          { name: "Home", href: PATH_DASHBOARD.root },
          {
            name: "Email Templates",
            href: PATH_DASHBOARD.emailTemplates.root,
          },
          {
            name: "New email template",
          },
        ]}
      />
      <EmailTemplateForm />
    </Container>
  );
}
