// material
import { styled } from "@mui/material/styles";
import { Box, Container } from "@mui/material";
// components
import LoginForm from "./LoginForm";
import logo from "../../assets/images/logo.jpg";

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login | The Initiatives Group">
      <Container maxWidth="sm">
        <ContentStyle>
          <Box
            component="img"
            alt="logo"
            src={logo}
            sx={{ width: "80%", mb: 4 }}
          />
          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
