import { useCallback, useEffect, useState } from "react";
import { useParams,useNavigate } from "react-router-dom";
// material
import { Container } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import EmailTemplateForm from "./EmailTemplateForm";
import LoadingScreen from "../../../components/LoadingScreen";
import ErrorMsg from "../../../components/ErrorMsg";
import useHttp from "../../../hooks/useHttp";
import useEmailTemplates from "../../../hooks/useEmailTemplates";

// ----------------------------------------------------------------------

export default function EmailTemplateEdit() {
  const [emailTemplate, setEmailTemplate] = useState();
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { sendRequest, isLoading } = useHttp();
  const [isFirstTime, setIsFirstTime] = useState(true);
  const {
    isLoading: emailTemplatesLoading,
    fetchEmailTemplates,
    error: emailTemplatesError,
  } = useEmailTemplates();

  const fetchTemplate = useCallback(async () => {
    setError(null);
    sendRequest({ url: `email-templates/${id}`, method: "GET", isPage: true }, (data) => {
      setEmailTemplate(data);
    },(error) => setError(error));
  }, [sendRequest, id]);

  useEffect(() => {
    if (isFirstTime) {
      fetchTemplate();
    }
    if (emailTemplatesLoading) {
      fetchEmailTemplates();
    }
    setIsFirstTime(false);
  }, [emailTemplatesLoading,fetchTemplate,fetchEmailTemplates,isFirstTime]);

  if (isLoading || emailTemplatesLoading) {
    return (
      <LoadingScreen
        sx={{
          height: "40vh",
        }}
      />
    );
  }

  if (emailTemplatesError) {
    return <ErrorMsg msg={error} action={fetchTemplate} />;
  }

  if(error){
    navigate('/404');
    return (
      <h2>Not Found</h2>
    );
  };

  return (
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading={"Edit email template"}
        links={[
          { name: "Home", href: PATH_DASHBOARD.root },
          {
            name: "Question Categories",
            href: PATH_DASHBOARD.emailTemplates.root,
          },
          {
            name: emailTemplate.emailTemplateTitle,
          },
        ]}
      />

      <EmailTemplateForm currentEmailTemplate={emailTemplate} />
    </Container>
  );
}
