import React, { useCallback, useReducer } from 'react';

import { useSnackbar } from 'notistack';

import useHttp from '../hooks/useHttp';

const QuestionsContext = React.createContext({
  list: [],
  error: null,
  isLoading: true,
  fetchQuestions: (typeId, contextType) => {},
  createQuestion: (typeId, question, type) => {},
  updateQuestion: (question, typeId, id, questionCategoryTitle) => {},
  deleteQuestion: (id, typeId) => {},
});

const questionsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALL': {
      return {
        ...state,
        list: action.list,
        isLoading: false,
        type: action.contextType,
        typeId: action.typeId,
      };
    }
    case 'UPDATE_QUESTION': {
      let id = 'companyQuestionId';
      if (state.type === 'templates') {
        id = 'templateQuestionId';
      }
      const questionIndex = state.list.findIndex((question) => question[id] === action.id);
      const updatedQuestion = {
        ...state.list[questionIndex],
        questionType: action.question.questionType,
        questionCategory: {
          questionCategoryId: action.question.questionCategory.questionCategoryId,
          questionCategoryTitle: action.questionCategoryTitle,
        },
        question: action.question.question,
        updatedAt: new Date().toISOString(),
      };
      const updatedQuestions = [...state.list];
      updatedQuestions[questionIndex] = updatedQuestion;
      return {
        ...state,
        list: updatedQuestions,
      };
    }
    case 'DELETE_QUESTION': {
      let id = 'companyQuestionId';
      if (state.type === 'templates') {
        id = 'templateQuestionId';
      }
      const updatedList = state.list.filter((question) => action.id !== question[id]);

      return {
        ...state,
        list: updatedList,
      };
    }
    case 'CREATE_QUESTION': {
      const updatedList = state.list.concat(action.question);
      return {
        ...state,
        list: updatedList,
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case 'ERROR': {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export const QuestionsContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { sendRequest: fetchQuestionsRequest } = useHttp();
  const { sendRequest: deleteQuestionRequest } = useHttp();

  const { sendRequest: updateQuestionRequest } = useHttp();

  const { sendRequest: createQuestionRequest } = useHttp();

  const [questions, dispatch] = useReducer(questionsReducer, {
    list: [],
    error: null,
    type: '',
    typeId: '',
    isLoading: true,
  });

  const { type } = questions;

  const fetchQuestions = useCallback(
    (typeId, contextType) => {
      const parentType = type ? type : contextType;
      dispatch({ type: 'SET_LOADING' });
      fetchQuestionsRequest(
        { url: `${parentType}/${typeId}/questions`, method: 'GET' },
        (data) => {
          dispatch({
            type: 'SET_ALL',
            contextType,
            list: data,
            typeId,
          });
        },
        (error) => {
          dispatch({ type: 'ERROR', error });
        },
      );
    },
    [fetchQuestionsRequest, type],
  );

  const deleteQuestionHandler = (id, typeId) => {
    dispatch({ type: 'DELETE_QUESTION', id });
    deleteQuestionRequest(
      { url: `${questions.type}/${typeId}/questions/${id}`, method: 'DELETE' },
      () => {
        enqueueSnackbar(`Question deleted successfully!`, {
          variant: 'success',
        });
      },
      () => {
        fetchQuestions(questions.typeId, questions.type);
      },
    );
  };

  const updateQuestionHandler = async (question, typeId, id, questionCategoryTitle) => {
    await updateQuestionRequest(
      {
        url: `${questions.type}/${typeId}/questions/${id}`,
        method: 'PATCH',
        body: question,
      },
      () => {
        enqueueSnackbar('Question successfully updated', {
          variant: 'success',
        });
        dispatch({
          type: 'UPDATE_QUESTION',
          question,
          id,
          questionCategoryTitle,
        });    
      },
      (error) => {
        throw new Error(error);
      }
    );
  };

  const createQuestionHandler = async (typeId, question, type) => {
    await createQuestionRequest(
      {
        url: type === 'simple' ? `${questions.type}/${typeId}/questions` : `companies/${typeId}/questions/template`,
        method: 'POST',
        body: question,
      },
      (data) => {
        dispatch({ type: 'CREATE_QUESTION', question: data });
        enqueueSnackbar(type === 'simple' ? 'Question created successfully!' : 'Questions from the template created successfully!', {
          variant: 'success',
        });
      },
      (error) => {
        throw new Error(error);
      }
    );
  };

  const contextValue = {
    list: questions.list,
    error: questions.error,
    isLoading: questions.isLoading,
    fetchQuestions: fetchQuestions,
    updateQuestion: updateQuestionHandler,
    deleteQuestion: deleteQuestionHandler,
    createQuestion: createQuestionHandler,
  };

  return <QuestionsContext.Provider value={contextValue}>{children}</QuestionsContext.Provider>;
};

export default QuestionsContext;
