import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { capitalCase } from "change-case";
import assessment from "@iconify/icons-ic/assessment";
import people from "@iconify/icons-ic/people";
import roundQuestionMark from "@iconify/icons-ic/round-question-mark";
// material
import { Container, Tab, Box, Tabs, Stack } from "@mui/material";

// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import { useParams } from "react-router";
import Assessments from "./Assessments";
import Employees from "./Employees";
import Questions from "./Questions";
// 
import useCompanies from "../../../hooks/useCompanies";
import LoadingScreen from "../../../components/LoadingScreen";
import ErrorMsg from "../../../components/ErrorMsg";

// ----------------------------------------------------------------------
let isFirstTime = true;
export default function CompanyView() {
  const [currentTab, setCurrentTab] = useState("assessments");
  const { id } = useParams();
  const {
    companyDetails,
    isLoading,
    error ,
    fetchCompanies
  } = useCompanies();

  useEffect(() => {
    if (isLoading && isFirstTime) {
      isFirstTime = false;
      fetchCompanies();
    }
  },[fetchCompanies,isLoading])

  if (isLoading) {
    return (
      <LoadingScreen
        sx={{
          height: "80vh",
        }}
      />
    );
  }

  if (error) {
    return <ErrorMsg msg={error} action={fetchCompanies} />;
  }

  const company = companyDetails(id);

  const COMPANY_TABS = [
    {
      value: "assessments",
      icon: <Icon icon={assessment} width={20} height={20} />,
      component: <Assessments companyTitle={company.companyTitle} companyId={id} />,
    },
    {
      value: "employees",
      icon: <Icon icon={people} width={20} height={20} />,
      component: <Employees companyTitle={company.companyTitle} companyId={id} />,
    },
    {
      value: "questions",
      icon: <Icon icon={roundQuestionMark} width={20} height={20} />,
      component: <Questions companyId={id} />,
    },
  ];

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Company Details"
        links={[
          { name: "Home", href: PATH_DASHBOARD.root },
          { name: "Companies", href: PATH_DASHBOARD.companies.root },
          { name: company.companyTitle },
        ]}
      />

      <Stack spacing={2}>
        <Tabs
          value={currentTab}
          scrollButtons="auto"
          variant="scrollable"
          allowScrollButtonsMobile
          onChange={handleChangeTab}
        >
          {COMPANY_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={capitalCase(tab.value)}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        {COMPANY_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Stack>
    </Container>
  );
}
