import { TextField, Card, IconButton, InputAdornment, Tooltip, Typography, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import copyOutline from '@iconify/icons-eva/copy-outline';
import { useSnackbar } from 'notistack';
import { QuillEditor } from '../../components/editor';
import { useLocation } from 'react-router-dom';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../routes/paths';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(1),
}));

export default function EmailExport() {
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();

  const copyHandler = (text) => {
    enqueueSnackbar(`successfully copied`, { variant: 'success' });
    navigator.clipboard.writeText(text);
  };

  if (!state) {
    return <h2>No Data is available!</h2>;
  }

  return (
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Email Export"
        links={[
          { name: 'Home', href: PATH_DASHBOARD.root },
          { name: 'Companies', href: PATH_DASHBOARD.companies.root },
          {
            name: state.companyTitle,
            href: `${PATH_DASHBOARD.companies.root}/${state.companyId}/${state.companyTitle}/view`,
          },
          {
            name: 'Email Export',
          },
        ]}
      />
      {state.list.map((employee, index) => (
        <Card sx={{ p: 3, mb: 3 }} key={employee.emailƒ}>
          <TextField
            fullWidth
            readOnly
            label="To"
            value={employee.email}
            sx={{ marginBottom: 2.5 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Copy">
                    <IconButton
                      edge="end"
                      onClick={() => {
                        copyHandler(employee.email);
                      }}
                    >
                      <Icon icon={copyOutline} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <LabelStyle>Message</LabelStyle>
          <QuillEditor simple id={`index-${index}`} value={employee.message} />
        </Card>
      ))}
    </Container>
  );
}
