import React, { useCallback, useReducer } from 'react';

import { useSnackbar } from 'notistack';

import useHttp from '../hooks/useHttp';

import { PATH_DASHBOARD } from '../routes/paths';

import { useNavigate } from 'react-router-dom';

const Templates = React.createContext({
  list: [],
  error: null,
  isLoading: true,
  fetchTemplates: () => {},
  createTemplate: (title) => {},
  templateDetails: (id) => {},
  updateTemplate: (template, id) => {},
  deleteTemplate: (id, title) => {},
});

const templatesReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALL': {
      return {
        ...state,
        list: action.list,
        isLoading: false,
      };
    }
    case 'UPDATE_TEMPLATE': {
      const templateIndex = state.list.findIndex((template) => template.templateId === action.id);
      const updatedTemplate = {
        createdAt: state.list[templateIndex].createdAt,
        templateId: state.list[templateIndex].templateId,
        templateTitle: action.template.templateTitle,
        updatedAt: new Date().toISOString(),
      };
      const updatedTemplates = [...state.list];
      updatedTemplates[templateIndex] = updatedTemplate;
      return {
        ...state,
        list: updatedTemplates,
      };
    }
    case 'DELETE_TEMPLATE': {
      const updatedList = state.list.filter((template) => action.id !== template.templateId);

      return {
        ...state,
        list: updatedList,
      };
    }
    case 'CREATE_TEMPLATE': {
      const updatedList = state.list.concat(action.template);

      return {
        ...state,
        list: updatedList,
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case 'ERROR': {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export const TemplatesProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { sendRequest: fetchTemplatesRequest } = useHttp();
  const { sendRequest: deleteTemplateRequest } = useHttp();

  const { sendRequest: updateTemplateRequest } = useHttp();

  const { sendRequest: createTemplateRequest } = useHttp();

  const [templates, dispatch] = useReducer(templatesReducer, {
    list: [],
    error: null,
    isLoading: true,
  });

  const fetchTemplates = useCallback(() => {
    dispatch({ type: 'SET_LOADING' });
    fetchTemplatesRequest(
      { url: 'templates', method: 'GET' },
      (data) => {
        dispatch({
          type: 'SET_ALL',
          list: data,
        });
      },
      (error) => {
        dispatch({ type: 'ERROR', error });
      },
    );
  }, [fetchTemplatesRequest]);

  const deleteTemplateHandler = (id, title) => {
    dispatch({ type: 'DELETE_TEMPLATE', id });
    navigate(PATH_DASHBOARD.templates.root);
    deleteTemplateRequest(
      { url: `templates/${id}`, method: 'DELETE' },
      () => {
        enqueueSnackbar(`${title} deleted successfully!`, {
          variant: 'success',
        });
      },
      () => {
        fetchTemplates();
      },
    );
  };

  const updateTemplateHandler = async(template, id) => {
    await updateTemplateRequest(
      {
        url: `templates/${id}`,
        method: 'PATCH',
        body: template,
      },
      (data) => {
        enqueueSnackbar(`${data.templateTitle} updated successfully!`, {
          variant: 'success',
        });
        dispatch({ type: 'UPDATE_TEMPLATE', template, id });
        navigate(PATH_DASHBOARD.templates.root);
      }
    );
  };

  const createTemplateHandler = async (title) => {
    await createTemplateRequest(
      {
        url: `templates`,
        method: 'POST',
        body: title,
      },
      (data) => {
        dispatch({ type: 'CREATE_TEMPLATE', template: data });
        enqueueSnackbar(`${data.templateTitle} created successfully!`, {
          variant: 'success',
        });
        navigate(`${PATH_DASHBOARD.templates.root}/${data.templateId}/view`);
      },
    );
  };

  const templateDetailsHandler = (id) => {
    const template = templates.list.find((template) => template.templateId === id);
    return template;
  };

  const contextValue = {
    list: templates.list,
    error: templates.error,
    isLoading: templates.isLoading,
    fetchTemplates: fetchTemplates,
    templateDetails: templateDetailsHandler,
    updateTemplate: updateTemplateHandler,
    deleteTemplate: deleteTemplateHandler,
    createTemplate: createTemplateHandler,
  };

  return <Templates.Provider value={contextValue}>{children}</Templates.Provider>;
};

export default Templates;
