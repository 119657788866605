import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
// material
import { Container } from '@mui/material';
import { Stack, Card, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

//

import { PATH_DASHBOARD } from '../../../routes/paths';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { useFormik, Form, FormikProvider } from 'formik';
import useHttp from '../../../hooks/useHttp';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function Profile() {
  const { enqueueSnackbar } = useSnackbar();
  const { sendRequest } = useHttp();
  const { login } = useAuth();

  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old Password is required'),
    newPassword: Yup.string().min(6, 'Password must be at least 6 characters').required('New Password is required'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm New Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: ChangePassWordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await sendRequest(
        {
          url: 'auth/new-password',
          method: 'PATCH',
          body: {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          },
          isAuth: true,
        },
        (data) => {
          login(data.accessToken);

          enqueueSnackbar('Your password has been changed successfully!', {
            variant: 'success',
          });

          setSubmitting(false);
        },
      );
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Container maxWidth={false}>
      <HeaderBreadcrumbs heading="Change Password" links={[{ name: 'Home', href: PATH_DASHBOARD.root }, { name: 'Change Password' }]} />
      <Card sx={{ p: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                {...getFieldProps('oldPassword')}
                fullWidth
                autoComplete="on"
                type="password"
                label="Old Password"
                error={Boolean(touched.oldPassword && errors.oldPassword)}
                helperText={touched.oldPassword && errors.oldPassword}
              />

              <TextField
                {...getFieldProps('newPassword')}
                fullWidth
                autoComplete="on"
                type="password"
                label="New Password"
                error={Boolean(touched.newPassword && errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
              />

              <TextField
                {...getFieldProps('confirmNewPassword')}
                fullWidth
                autoComplete="on"
                type="password"
                label="Confirm New Password"
                error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
                helperText={touched.confirmNewPassword && errors.confirmNewPassword}
              />

              <LoadingButton
                type="submit"
                variant="contained"
                size="large"
                loading={isSubmitting}
                sx={{
                  width: 180,
                }}
              >
                Save Changes
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Card>
    </Container>
  );
}
