import React, { useReducer } from "react";
import jwtDecode from "jwt-decode";

const AuthContext = React.createContext({
  token: "",
  username: "",
  role: "",
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
  error: null,
});

const retrieveStoredData = () => {
  const storedToken = localStorage.getItem("token");
  if (storedToken === null) {
    return null;
  }
  return storedToken;
};

const decodeToken = (accessToken) => {
  if (!accessToken) {
    return null;
  }
  try {
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    if (decoded.exp > currentTime) {
      return decoded;
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

const userReducer = (state, action) => {
  switch (action.type) {
    case "SET": {
      return {
        name: action.name,
        role: action.role,
        token: action.token,
        error: null,
      };
    }
    case "DELETE": {
      return {
        ...state,
        name: "",
        role: "",
        token: "",
      };
    }
    case "SET_ERROR": {
      return {
        ...state,
        error: action.error,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

let firstTime = true;

export const AuthContextProvider = (props) => {
  const [user, dispatch] = useReducer(userReducer, {
    name: "",
    role: "",
    token: "",
    error: null,
  });

  const logoutHandler = () => {
    dispatch({
      type: "DELETE",
    });
    localStorage.removeItem("token");
  };

  const loginHandler = (token) => {
    const decodedToken = decodeToken(token);
    if (decodedToken) {
      localStorage.setItem("token", token);
      dispatch({
        type: "SET",
        name: decodedToken.name,
        role: decodedToken.role,
        token: token,
      });
    }
  };

  const userIsLoggedIn = !!user.token;

  const storedData = retrieveStoredData();

  if (firstTime && storedData) {
    loginHandler(storedData);
    firstTime = false;
  }

  const contextValue = {
    token: user.token,
    username: user.name,
    role: user.role,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    error: user.error,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
