import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
// material
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";

const RootStyle = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.background.default,
}));

ErrorMsg.propTypes = {
  msg: PropTypes.string,
  action: PropTypes.func,
};

export default function ErrorMsg({ msg,url }) {
  return (
    <RootStyle>
      <Box
        sx={{
          maxWidth: 480,
          margin: "auto",
          textAlign: "center",
          height: "45vh",
        }}
      >
        <Typography variant="h3" paragraph>
          {msg}
        </Typography>
        <Button
            variant="contained"
            component={RouterLink}
            to={url}
            startIcon={<Icon icon={plusFill} />}
          >
            Add New
          </Button>
      </Box>
    </RootStyle>
  );
}
