import { useCallback, useState } from "react";
import useAuth from "./useAuth";
import { useSnackbar } from "notistack";
import { MIconButton } from "../components/@material-extend";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";

const useHttp = () => {
  const { token, logout } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const sendRequest = useCallback(
    async (requestConfig, onSuccess, onError) => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/${requestConfig.url}`,
          {
            method: requestConfig.method,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: requestConfig.body
              ? JSON.stringify(requestConfig.body)
              : null,
          }
        );

        let data = null;
        if (requestConfig.method !== "DELETE" && requestConfig.type !== "email") {
          data = await response.json();
        }

        if (!response.ok) {
          const statusCode = response.status;

          if (statusCode === 401 || statusCode === 403) {
            if (!requestConfig.isAuth) {
              logout();
              throw new Error("Session expired!");
            }

            throw new Error(data.message);
          } else if (statusCode === 400) {
            data.errors.forEach((error) => {
              enqueueSnackbar(error.message, {
                variant: "error",
                action: (key) => (
                  <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                    <Icon icon={closeFill} />
                  </MIconButton>
                ),
              });
            });

            throw new Error("");
          } else if (statusCode === 409 || (requestConfig.isPage && statusCode === 404)) {
            throw new Error(data.message);
          }
          else {
            throw new Error("Something Went Wrong!");
          }
        }
        if (onSuccess) {
          onSuccess(data);
        }
        setIsLoading(false);
      } catch (error) {
        if (!!error.message) {
          if (onError) {
            onError(error.message);
          }
          enqueueSnackbar(error.message, {
            variant: "error",
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
          setIsLoading(false);
        }
      }
    },
    [token, logout, enqueueSnackbar, closeSnackbar]
  );
  return { isLoading, sendRequest };
};
export default useHttp;
