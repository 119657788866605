import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { Icon } from '@iconify/react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import downloadFill from '@iconify/icons-eva/download-fill';
import refreshFill from '@iconify/icons-eva/refresh-outline';
// material
import { Stack, Button } from '@mui/material';
//
import CreateEditEmployee from './CreateEditEmployee';
import useEmployees from '../../../../hooks/useEmployees';
import swal from 'sweetalert';
import EmployeeCredentials from './EmployeeCredentials';
import EmployeeLogin from './EmployeeLogin';
import { LoadingButton } from '@mui/lab';
import useHttp from '../../../../hooks/useHttp';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useSnackbar } from 'notistack';
import useAssessments from '../../../../hooks/useAssessments';

// ----------------------------------------------------------------------

EmployeeTableActions.propTypes = {
  companyId: PropTypes.string,
  employee: PropTypes.object,
  companyHaveActiveAssessments: PropTypes.bool,
  employeesOptionList: PropTypes.array,
};

export default function EmployeeTableActions({ employee, companyId, companyHaveActiveAssessments, employeesOptionList }) {
  const { deleteEmployee, resetStatus } = useEmployees();
  const { resetAssessment } = useAssessments();
  const { enqueueSnackbar } = useSnackbar();
  const { sendRequest } = useHttp();

  const [isLoading, setIsLoading] = useState(false);
  const [isResetAssessmentLoading, setIsResetAssessmentLoading] = useState(false);

  const deleteButtonHandler = () => {
    swal({
      title: 'Are you sure?',
      text: 'You want to delete it?',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: { text: 'Delete', className: 'bg-info' },
      },
    }).then((isDelete) => {
      if (isDelete) {
        deleteEmployee(companyId, employee.companyEmployeeId, `${employee.firstName} ${employee.lastName}`);
      }
    });
  };

  const downloadReportHandler = async () => {
    setIsLoading(true);

    await sendRequest(
      {
        url: `companies/${companyId}/employees/${employee.companyEmployeeId}/scores`,
        method: 'GET',
      },
      (resData) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        const new_workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(resData.assessment);
        XLSX.utils.book_append_sheet(new_workbook, worksheet, `${employee.firstName} ${employee.lastName}`);
        const excelBuffer = XLSX.write(new_workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, `${employee.firstName} ${employee.lastName}`);
      },
    );

    setIsLoading(false);
  };

  const resetAssessmentHandler = () => {
    swal({
      title: 'Are you sure?',
      text: 'You want to reset the assessment?',
      icon: 'warning',
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: { text: 'Reset', className: 'bg-info' },
      },
    }).then(async (isReset) => {
      if (isReset) {
        setIsResetAssessmentLoading(true);

        await sendRequest(
          {
            url: `companies/${companyId}/employees/${employee.companyEmployeeId}/reset-assessment`,
            method: 'POST',
          },
          (data) => {
            resetStatus(data.employee.companyEmployeeId, data.employee.assessStatus);
            resetAssessment(data.assessment.companyAssessmentId, data.assessment.statusPercentage);
            enqueueSnackbar(`Assessment reseted successfully!`, {
              variant: 'success',
            });
          },
        );

        setIsResetAssessmentLoading(false);
      }
    });
  };

  return (
    <Stack direction="row" justifyContent="flex-end">
      {companyHaveActiveAssessments && (
        <Fragment>
          <LoadingButton
            size="small"
            color="primary"
            loading={isLoading}
            variant="contained"
            loadingPosition="start"
            startIcon={<Icon icon={downloadFill} />}
            onClick={downloadReportHandler}
            sx={{ ml: 1 }}
          >
            Report
          </LoadingButton>
          <LoadingButton
            size="small"
            color="secondary"
            loading={isResetAssessmentLoading}
            variant="contained"
            loadingPosition="start"
            startIcon={<Icon icon={refreshFill} />}
            onClick={resetAssessmentHandler}
            sx={{ ml: 1 }}
          >
            Reset&nbsp;Assessment
          </LoadingButton>
        </Fragment>
      )}
      <EmployeeLogin id={employee.companyEmployeeId} companyId={companyId} />
      <CreateEditEmployee companyId={companyId} currentEmployee={employee} />
      <EmployeeCredentials id={employee.companyEmployeeId} companyId={companyId} />
      <Button
        color="error"
        size="small"
        variant="contained"
        onClick={deleteButtonHandler}
        sx={{ ml: 1, color: '#ffffff' }}
        startIcon={<Icon icon={trash2Outline} />}
      >
        Delete
      </Button>
    </Stack>
  );
}
