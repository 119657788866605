import { useState, forwardRef, Fragment, useCallback } from 'react';
// material
import {
  Slide,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import lockOutline from '@iconify/icons-eva/lock-outline';
import { Icon } from '@iconify/react';
import useHttp from '../../../../hooks/useHttp';
import ErrorMsg from '../../../../components/ErrorMsg';
import copyOutline from '@iconify/icons-eva/copy-outline';
import { useSnackbar } from 'notistack';
import LoadingScreen from '../../../../components/LoadingScreen';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function EmployeeCredentials({ id, companyId }) {
  const [open, setOpen] = useState(false);
  const [credentials, setCredentials] = useState([]);
  const { sendRequest, isLoading } = useHttp();
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
    fetchCredentialsHandler();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchCredentialsHandler = useCallback(() => {
    setError(null);
    sendRequest(
      {
        url: `companies/${companyId}/employees/${id}/credentials`,
        method: 'GET',
      },
      (data) => {
        setCredentials(data);
      },
      (error) => {
        setError(error);
      },
    );
  }, [sendRequest, companyId, id]);

  const copyHandler = (title, text) => {
    enqueueSnackbar(`${title} copied successfully!`, { variant: 'success' });
    navigator.clipboard.writeText(text);
  };

  return (
    <Fragment>
      <Button
        color="info"
        size="small"
        variant="contained"
        onClick={handleClickOpen}
        sx={{ ml: 1, color: '#ffffff' }}
        startIcon={<Icon icon={lockOutline} />}
      >
        Credentials
      </Button>
      <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} maxWidth="md" fullWidth>
        <DialogTitle sx={{ mb: 2 }}>Employee Credentials</DialogTitle>
        <DialogContent sx={{ pt: '6px !important' }}>
          {isLoading && !error && (
            <LoadingScreen
              sx={{
                height: '16vh',
              }}
            />
          )}
          {!isLoading && error && (
            <ErrorMsg
              msg={error}
              action={() => {
                fetchCredentialsHandler(companyId);
              }}
            />
          )}
          {!isLoading && !error && (
            <Fragment>
              <TextField
                fullWidth
                readOnly
                label="Link"
                value={credentials.url}
                sx={{ marginBottom: 2.5 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Copy">
                        <IconButton
                          edge="end"
                          onClick={() => {
                            copyHandler('Link', credentials.url);
                          }}
                        >
                          <Icon icon={copyOutline} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                readOnly
                label="Code"
                value={credentials.code}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Copy">
                        <IconButton
                          edge="end"
                          onClick={() => {
                            copyHandler('Code', credentials.code);
                          }}
                        >
                          <Icon icon={copyOutline} />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
