import { useEffect } from "react";
import { useParams,useNavigate, useLocation } from "react-router-dom";
// material
import { Container } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";
import QuestionCategoryForm from "./QuestionCategoryForm";
import LoadingScreen from "../../../components/LoadingScreen";
// hooks
import useQuestionCategories from "../../../hooks/useQuestionCategories";
import ErrorMsg from "../../../components/ErrorMsg";
// ----------------------------------------------------------------------
let isFirstTime = true;
export default function QuestionCategoryCreate() {
  const { questionCategoryDetails, isLoading, fetchQuestionCategories, error } =
    useQuestionCategories();
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const isEdit = pathname.includes("edit");

  useEffect(() => {
    if (isLoading && isFirstTime) {
      fetchQuestionCategories();
    }
  }, [fetchQuestionCategories, isLoading]);

  if (isLoading) {
    return (
      <LoadingScreen
        sx={{
          height: "80vh",
        }}
      />
    );
  }
  const questionCategory = questionCategoryDetails(id);

  if (error) {
    return <ErrorMsg msg={error} action={fetchQuestionCategories} />;
  }

  if(isEdit && !questionCategory){
    navigate('/404');
    return <h2>Not Found!</h2>
  }

  return (
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading={
          !isEdit ? "Create a new question category" : "Edit question category"
        }
        links={[
          { name: "Home", href: PATH_DASHBOARD.root },
          {
            name: "Question Categories",
            href: PATH_DASHBOARD.questionCategories.root,
          },
          {
            name: !isEdit
              ? "New question category"
              : questionCategory.questionCategoryTitle,
          },
        ]}
      />

      <QuestionCategoryForm
        isEdit={isEdit}
        currentQuestionCategory={questionCategory}
      />
    </Container>
  );
}
