import { useState, forwardRef, Fragment, useCallback } from "react";
// material
import {
  Slide,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack
} from "@mui/material";
import personOutline from "@iconify/icons-eva/person-outline";
import { Icon } from "@iconify/react";
import useHttp from "../../../../hooks/useHttp";
import ErrorMsg from "../../../../components/ErrorMsg";
import LoadingScreen from "../../../../components/LoadingScreen";

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function EmployeeLogin({ id, companyId }) {
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [error, setError] = useState(null);
  const { sendRequest, isLoading } = useHttp();

  const handleClickOpen = () => {
    setOpen(true);
    fetchUrlHandler();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchUrlHandler = useCallback(() => {
    setError(null);
    sendRequest(
      {
        url: `companies/${companyId}/employees/${id}/credentials`,
        method: "GET",
      },
      (data) => {
        setUrl(`${data.url}/${data.code}`);
      },
      (error) => {
        setError(error);
      },
    );
  }, [sendRequest, companyId, id]);

  return (
    <Fragment>
      <Button
        color="success"
        size="small"
        variant="contained"
        onClick={handleClickOpen}
        sx={{ ml: 1, color: "#ffffff" }}
        startIcon={<Icon icon={personOutline} />}
      >
        Login
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ mb: 2 }}>Employee Login</DialogTitle>
        <DialogContent sx={{ pt: "6px !important" }}>
          {isLoading && !error && (
            <LoadingScreen
              sx={{
                height: "16vh",
              }}
            />
          )}
          {!isLoading && error && (
            <ErrorMsg
              msg={error}
              action={() => {
                fetchUrlHandler();
              }}
            />
          )}
          {!isLoading && !error && (
            <Stack 
              direction="row"
              justifyContent="center"
              sx={{pt: 2}}
            >
              <Button 
                color="primary"
                size="medium"
                variant="contained"
                target="_blank"
                href={url}
                rel="noopener noreferrer"
              > 
                  Click here to Login
              </Button>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
