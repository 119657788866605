import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import ErrorMsg from "../../../../components/ErrorMsg";
import LoadingScreen from "../../../../components/LoadingScreen";
import CreateEditAssessment from "./CreateEditAssessment";
import AssessmentTable from "./AssessmentTable";
import useAssessments from "../../../../hooks/useAssessments";
import useEmailTemplates from "../../../../hooks/useEmailTemplates";
import {
  Tooltip,
  Typography
} from "@mui/material";

Assessments.propTypes = {
  companyId: PropTypes.string,
  companyTitle: PropTypes.string,
};

const TABLE_HEAD = [
  { id: "companyAssessmentTitle", label: "Title", alignRight: false },
  { id: "createdAt", label: "Started at", alignRight: false },
  { id: "finishedAt", label: "Finished at", alignRight: false },
  { id: "assessmentType", label: "Type", alignRight: false },
  { id: "showPreviousScore", label: <Tooltip title="Show Previous Score"><Typography variant="inherit">Previous</Typography></Tooltip>, alignRight: false },
  { id: "statusPercentage", label: "Status", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

export default function Assessments({ companyId, companyTitle }) {
  const [isFirstTime, setIsFirstTime] = useState(true);
  const {
    isLoading,
    fetchAssessments,
    error,
    list: assessments,
  } = useAssessments();

  const {
    isLoading: areTemplatesLoading,
    fetchEmailTemplates,
    templatesError,
  } = useEmailTemplates();

  useEffect(() => {
    if (isLoading && isFirstTime) {
      fetchAssessments(companyId);
    }
    if (areTemplatesLoading && isFirstTime) {
      fetchEmailTemplates();
    }
    setIsFirstTime(false);
  }, [
    fetchAssessments,
    isLoading,
    companyId,
    fetchEmailTemplates,
    areTemplatesLoading,
    isFirstTime
  ]);

  if (isLoading || areTemplatesLoading) {
    return (
      <LoadingScreen
        sx={{
          height: "50vh",
        }}
      />
    );
  }

  if (error) {
    return (
      <ErrorMsg
        msg={error}
        action={() => {
          fetchAssessments(companyId);
        }}
      />
    );
  }

  if (templatesError) {
    return (
      <ErrorMsg
        msg={error}
        action={() => {
          fetchEmailTemplates();
        }}
      />
    );
  }

  return (
    <Fragment>
      <CreateEditAssessment companyId={companyId} />
      {assessments.length > 0 && (
        <AssessmentTable
          list={assessments}
          companyId={companyId}
          tableHead={TABLE_HEAD}
          companyTitle={companyTitle}
        />
      )}
    </Fragment>
  );
}
