import * as Yup from "yup";
import { Fragment, useState, forwardRef, useEffect } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import {
  Slide,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  Skeleton,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ErrorMsg from "../../../../components/ErrorMsg";
import useTemplates from "../../../../hooks/useTemplates";
import useQuestions from "../../../../hooks/useQuestions";

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const SkeletonLoad = (
  <>
    {[...Array(1)].map((_, index) => (
      <Grid item xs={12} key={index}>
        <Skeleton
          variant="rectangular"
          width="100%"
          sx={{ p: 4, mb: 1, borderRadius: 2 }}
        />
      </Grid>
    ))}
  </>
);
let isFirstTime = true;
export default function CreateTemplateQuestions({ companyId }) {
  const [open, setOpen] = useState(false);
  const { fetchTemplates, isLoading, error, list: templates } = useTemplates();
  const { createQuestion } = useQuestions();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const NewTemplateQuestionsSchema = Yup.object().shape({
    template: Yup.object().required("Template is required").nullable(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      template: templates[0],
    },
    validationSchema: NewTemplateQuestionsSchema,
    onSubmit: async (values, { resetForm }) => {
      await createQuestion(
        companyId,
        {
          templateId: values.template.templateId,
        },
        "template"
      );
      resetForm();
      handleClose();
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    values,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (isLoading && isFirstTime) {
      fetchTemplates();
    }
  }, [fetchTemplates, isLoading]);

  if (error) {
    return <ErrorMsg msg={error} action={fetchTemplates} />;
  }

  return (
    <Fragment>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<Icon icon={plusFill} />}
        sx={{ mr: 1 }}
      >
        Create questions from template
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        maxWidth="md"
        fullWidth
      >
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <DialogTitle sx={{ mb: 2 }}>
              Create questions from template
            </DialogTitle>
            <DialogContent sx={{ pt: "6px !important" }}>
              {isLoading ? (
                SkeletonLoad
              ) : (
                <Autocomplete
                  value={values.template}
                  disablePortal
                  onChange={(event, newValue) => {
                    setFieldValue("template", newValue);
                  }}
                  options={templates}
                  getOptionLabel={(option) =>
                    option.templateTitle || values.template
                  }
                  renderInput={(params) => (
                    <TextField
                      label="Category"
                      {...params}
                      fullWidth
                      {...getFieldProps("template")}
                      error={Boolean(touched.template && errors.template)}
                      helperText={touched.template && errors.template}
                      sx={{ marginBottom: 2.5 }}
                    />
                  )}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="inherit">
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Create
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </Fragment>
  );
}
