import PropTypes from "prop-types";
import { Fragment, useEffect } from "react";
import ErrorMsg from "../../../../components/ErrorMsg";
import LoadingScreen from "../../../../components/LoadingScreen";
import useQuestions from "../../../../hooks/useQuestions";
import useQuestionCategories from "../../../../hooks/useQuestionCategories";
import CreateEditQuestion from "./CreateEditQuestion";
import QuestionsTable from "./QuestionsTable";
import CreateTemplateQuestions from "./CreateTemplateQuestions";

import { Box } from "@mui/material";

Questions.propTypes = {
  companyId: PropTypes.string,
};

const TABLE_HEAD = [
  { id: "questionCategoryTitle", label: "Category", alignRight: false },
  { id: "question", label: "Question", alignRight: false },
  { id: "questionType", label: "Type", alignRight: false },
  { id: "createdAt", label: "Created at", alignRight: false },
  { id: "updatedAt", label: "Updated at", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

let isFirstTime = true;

export default function Questions({ companyId }) {
  const {
    fetchQuestions,
    isLoading,
    error,
    list: companyQuestions,
  } = useQuestions();
  const {
    isLoading: isQuestionCategoriesLoading,
    fetchQuestionCategories,
    error: questionCategoriesError,
  } = useQuestionCategories();

  useEffect(() => {
    if (isQuestionCategoriesLoading && isFirstTime) {
      fetchQuestionCategories();
    }
    if (isLoading && isFirstTime) {
      fetchQuestions(companyId, "companies");
    }
  }, [
    fetchQuestions,
    isLoading,
    companyId,
    isQuestionCategoriesLoading,
    fetchQuestionCategories,
  ]);

  if (isLoading || isQuestionCategoriesLoading) {
    return (
      <LoadingScreen
        sx={{
          height: "80vh",
        }}
      />
    );
  }

  if (error || questionCategoriesError) {
    return (
      <ErrorMsg
        msg={error}
        action={() => {
          fetchQuestions(companyId, "companies");
        }}
      />
    );
  }

  const companyQuestionsList = companyQuestions.map((companyQuestion) => ({
    ...companyQuestion,
    questionCategoryTitle: companyQuestion.questionCategory.questionCategoryTitle
  })); 


  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: 2,
        }}
      >
        <CreateTemplateQuestions companyId={companyId} />
        <CreateEditQuestion typeId={companyId} />
      </Box>
      {companyQuestions.length > 0 && (
        <QuestionsTable
          list={companyQuestionsList}
          tableHead={TABLE_HEAD}
          typeId={companyId}
        />
      )}
    </Fragment>
  );
}
