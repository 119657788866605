import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";
import editFill from "@iconify/icons-eva/edit-fill";
import eyeOutline from "@iconify/icons-eva/eye-outline";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
// material
import { Button, Container } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";
// components
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";

import Table from "../../components/table";
import ErrorMsg from "../../components/ErrorMsg";
import NoData from "../../components/NoData";
import LoadingScreen from "../../components/LoadingScreen";
import useCompanies from "../../hooks/useCompanies";
import { useEffect } from "react";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  // { id: "companyId", label: "ID", alignRight: false },
  { id: "companyTitle", label: "Title", alignRight: false },
  { id: "createdAt", label: "Created at", alignRight: false },
  { id: "updatedAt", label: "Updated at", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

// ----------------------------------------------------------------------
let isFirstTime = true;
export default function Companies() {
  const {
    list: companies,
    deleteCompany,
    fetchCompanies,
    isLoading,
    error,
  } = useCompanies();

  useEffect(() => {
    if (isLoading && isFirstTime) {
      isFirstTime = false;
      fetchCompanies();
    }
  }, [fetchCompanies, isLoading]);

  if (isLoading) {
    return (
      <LoadingScreen
        sx={{
          height: "80vh",
        }}
      />
    );
  }
  
  if (error) {
    return <ErrorMsg msg={error} action={fetchCompanies} />;
  }

  if (companies.length === 0) {
    return (
      <NoData msg="No Companies Found!" url={PATH_DASHBOARD.companies.create} />
    );
  }

  const TABLE_ACTIONS = [
    {
      type: "url",
      label: "View",
      urlType: "view",
      icon: eyeOutline,
      color: "info",
    },
    {
      type: "url",
      label: "Edit",
      urlType: "edit",
      icon: editFill,
      color: "primary",
    },
    {
      type: "button",
      label: "Delete",
      clickHandler: deleteCompany,
      icon: trash2Outline,
      color: "error",
    },
  ];

  return (
    <Container maxWidth={false}>
      <HeaderBreadcrumbs
        heading="Companies"
        links={[
          { name: "Home", href: PATH_DASHBOARD.root },
          { name: "Companies" },
        ]}
        action={
          <Button
            variant="contained"
            component={RouterLink}
            to={PATH_DASHBOARD.companies.create}
            startIcon={<Icon icon={plusFill} />}
          >
            Add New
          </Button>
        }
      />
      <Table
        list={companies}
        tableHead={TABLE_HEAD}
        tableActions={TABLE_ACTIONS}
        name="companies"
        viewTitle
        title="companyTitle"
      />
    </Container>
  );
}
