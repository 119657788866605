import React, { useCallback, useReducer } from 'react';

import { useSnackbar } from 'notistack';

import useHttp from '../hooks/useHttp';

const EmployeesContext = React.createContext({
  list: [],
  error: null,
  isLoading: true,
  types: [],
  fetchEmployees: (companyId) => {},
  createEmployee: (companyId, employee) => {},
  updateEmployee: (companyId, employee, id) => {},
  deleteEmployee: (companyId, id, name) => {},
  resetStatus: (id) => {},
});

const employeesReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ALL': {
      return {
        ...state,
        companyId: action.companyId,
        list: action.list,
        isLoading: false,
      };
    }
    case 'UPDATE_EMPLOYEE': {
      const employeeIndex = state.list.findIndex((employee) => employee.companyEmployeeId === action.employee.companyEmployeeId);
      const updatedEmployees = [...state.list];
      updatedEmployees[employeeIndex] = action.employee;

      return {
        ...state,
        list: updatedEmployees,
      };
    }
    case 'DELETE_EMPLOYEE': {
      const updatedList = state.list.filter((employee) => action.id !== employee.companyEmployeeId);

      return {
        ...state,
        list: updatedList,
      };
    }
    case 'CREATE_EMPLOYEE': {
      const updatedList = state.list.concat(action.employee);

      return {
        ...state,
        list: updatedList,
      };
    }
    case 'RESET_STATUS': {
      const employeeIndex = state.list.findIndex((employee) => employee.companyEmployeeId === action.id);

      const updatedEmployee = {
        ...state.list[employeeIndex],
        assessStatus: action.status,
      };
      const updatedEmployees = [...state.list];
      updatedEmployees[employeeIndex] = updatedEmployee;

      return {
        ...state,
        list: updatedEmployees,
      };
    }
    case 'SET_LOADING': {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case 'ERROR': {
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export const EmployeesContextProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { sendRequest: fetchEmployeesRequest } = useHttp();

  const { sendRequest: deleteEmployeeRequest } = useHttp();

  const { sendRequest: updateEmployeeRequest } = useHttp();

  const { sendRequest: createEmployeeRequest } = useHttp();

  const [employees, dispatch] = useReducer(employeesReducer, {
    list: [],
    companyId: '',
    error: null,
    isLoading: true,
  });

  const fetchEmployees = useCallback(
    (companyId) => {
      dispatch({ type: 'SET_LOADING' });
      fetchEmployeesRequest(
        { url: `companies/${companyId}/employees`, method: 'GET' },
        (data) => {
          dispatch({
            type: 'SET_ALL',
            list: data,
            companyId,
          });
        },
        (error) => {
          dispatch({ type: 'ERROR', error });
        },
      );
    },
    [fetchEmployeesRequest],
  );

  const deleteEmployeeHandler = (companyId, id, name) => {
    dispatch({ type: 'DELETE_EMPLOYEE', id });
    deleteEmployeeRequest(
      { url: `companies/${companyId}/employees/${id}`, method: 'DELETE' },
      () => {
        enqueueSnackbar(`${name} deleted successfully!`, {
          variant: 'success',
        });
      },
      () => {
        fetchEmployees(employees.companyId);
      },
    );
  };

  const updateEmployeeHandler = async (companyId, employee, id) => {
    await updateEmployeeRequest(
      {
        url: `companies/${companyId}/employees/${id}`,
        method: 'PATCH',
        body: employee,
      },
      (data) => {
        dispatch({ type: 'UPDATE_EMPLOYEE', employee: data });
        enqueueSnackbar(`${data.firstName} ${data.lastName} updated successfully!`, {
          variant: 'success',
        });
      },
      (error) => {
        throw new Error(error);
      }
    );
  };

  const createEmployeeHandler = async (companyId, employee) => {
    await createEmployeeRequest(
      {
        url: `companies/${companyId}/employees`,
        method: 'POST',
        body: employee,
      },
      (data) => {
        dispatch({ type: 'CREATE_EMPLOYEE', employee: data });
        enqueueSnackbar(`${data.firstName} ${data.lastName} created successfully!`, {
          variant: 'success',
        });
      },
      (error) => {
        throw new Error(error);
      }
    );
  };

  const resetStatusHandler = (id, status) => {
    dispatch({ type: 'RESET_STATUS', id, status });
  };

  const contextValue = {
    list: employees.list,
    error: employees.error,
    isLoading: employees.isLoading,
    fetchEmployees: fetchEmployees,
    updateEmployee: updateEmployeeHandler,
    deleteEmployee: deleteEmployeeHandler,
    createEmployee: createEmployeeHandler,
    resetStatus: resetStatusHandler,
  };

  return <EmployeesContext.Provider value={contextValue}>{children}</EmployeesContext.Provider>;
};

export default EmployeesContext;
