import * as Yup from 'yup';
import { Fragment, useState, forwardRef } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import editFill from '@iconify/icons-eva/edit-fill';
// material
import {
  Box,
  Slide,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  FormControlLabel,
  Switch,
  Tooltip,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAssessments from '../../../../hooks/useAssessments';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function CreateEditAssessment({ companyId, currentAssessment }) {
  const [open, setOpen] = useState(false);
  const { createAssessment, updateAssessment, types } = useAssessments();
  const [error, setError] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const NewAssessmentSchema = Yup.object().shape(
    currentAssessment
      ? {
          companyAssessmentTitle: Yup.string().required('Title is required'),
        }
      : {
          companyAssessmentTitle: Yup.string().required('Title is required'),
          assessmentType: Yup.mixed().oneOf(types, 'Type is required'),
        },
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: currentAssessment
      ? {
          companyAssessmentTitle: currentAssessment.companyAssessmentTitle,
          showPreviousScore: currentAssessment.showPreviousScore,
        }
      : {
          assessmentType: types[0],
          companyAssessmentTitle: '',
          showPreviousScore: false,
        },
    validationSchema: NewAssessmentSchema,
    onSubmit: async (values, { resetForm }) => {
      setError(null);
      if (currentAssessment) {
        try{
          await updateAssessment(companyId, values, currentAssessment.companyAssessmentId);
          handleClose();
        }
        catch(err){
          setError(err.message);
        }
      }
      else {
        try{
          await createAssessment(companyId, values);
          resetForm();
          handleClose();
        }
        catch(err){
          setError(err.message);
        }
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values, setFieldValue } = formik;

  return (
    <Fragment>
      {currentAssessment ? (
        <Tooltip title="Edit">
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={handleClickOpen}
            sx={{ ml: 1, maxWidth: 40, maxHeight: 40, minWidth: 40, minHeight: 40 }}
          >
            <Icon icon={editFill} width={40} height={40} />
          </Button>
        </Tooltip>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: 2,
          }}
        >
          <Button variant="contained" onClick={handleClickOpen} startIcon={<Icon icon={plusFill} />}>
            Add New
          </Button>
        </Box>
      )}
      <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} maxWidth="md" fullWidth>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <DialogTitle sx={{ mb: 2 }}>{currentAssessment ? 'Edit assessment' : 'Add new assessment'}</DialogTitle>
            <DialogContent sx={{ pt: '6px !important' }}>
              {!currentAssessment && (
                <Autocomplete
                  value={values.assessmentType}
                  disablePortal
                  onChange={(event, newValue) => {
                    setFieldValue('assessmentType', newValue);
                  }}
                  options={types.map((option) => option)}
                  renderInput={(params) => (
                    <TextField
                      label="Type"
                      {...params}
                      fullWidth
                      {...getFieldProps('assessmentType')}
                      error={Boolean(touched.assessmentType && errors.assessmentType)}
                      helperText={touched.assessmentType && errors.assessmentType}
                      sx={{ marginBottom: 2.5 }}
                    />
                  )}
                />
              )}
              <TextField
                fullWidth
                label="Assessment Title"
                {...getFieldProps('companyAssessmentTitle')}
                error={Boolean(touched.companyAssessmentTitle && errors.companyAssessmentTitle)}
                helperText={touched.companyAssessmentTitle && errors.companyAssessmentTitle}
                sx={{ marginBottom: 2.5 }}
              />
              <FormControlLabel
                control={<Switch {...getFieldProps('showPreviousScore')} checked={values.showPreviousScore} />}
                label="Show Previous Score"
              />
              {error && <Alert severity="error" sx={{mt: 2}}>{error}</Alert>}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="inherit">
                Cancel
              </Button>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {currentAssessment ? 'Save Changes' : 'Create'}
              </LoadingButton>
            </DialogActions>
          </Form>
        </FormikProvider>
      </Dialog>
    </Fragment>
  );
}
