import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import { Link as RouterLink } from "react-router-dom";
// material
import { Stack, Button, Tooltip } from "@mui/material";

import swal from "sweetalert";

// ----------------------------------------------------------------------

TableActions.propTypes = {
  url: PropTypes.string,
  actions: PropTypes.array,
  id: PropTypes.string,
  title: PropTypes.string,
};

export default function TableActions({ id, title, url, actions }) {
  const deleteButtonHandler = (action) => {
    swal({
      title: "Are you sure?",
      text: "You want to delete it?",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: { text: "Delete", className: "bg-info" },
      },
    }).then((isDelete) => {
      if (isDelete) {
        action.clickHandler(id, title);
      }
    });
  };

  return (
    <Stack direction="row" justifyContent="flex-end">
      {actions.map((action) => {
        if (action.type === "button") {
          return (
            <Tooltip title={action.label} key={action.label}>
              <Button
                color={action.color}
                size="small"
                variant="contained"
                onClick={() => {
                  if (action.label === "Delete") {
                    deleteButtonHandler(action);
                  } else {
                    action.clickHandler(id);
                  }
                }}
                sx={{ ml: 1, maxWidth: 40, maxHeight: 40, minWidth: 40, minHeight: 40}}
              >
                <Icon icon={action.icon} width={40} height={40} />
              </Button>
            </Tooltip>
          );
        } else {
          return (
            <Tooltip title={action.label} key={action.label}>
              <Button
                color={action.color}
                size="small"
                variant="contained"
                component={RouterLink}
                to={`${url}${action.urlType}`}
                sx={{ ml: 1, maxWidth: 40, maxHeight: 40, minWidth: 40, minHeight: 40}}
              >
                <Icon icon={action.icon} width={40} height={40} />
              </Button>
            </Tooltip>
          );
        }
      })}
    </Stack>
  );
}
