import * as Yup from "yup";
import PropTypes from "prop-types";
import { Form, FormikProvider, useFormik } from "formik";
// material
import { LoadingButton } from "@mui/lab";
import { Card, Grid, Stack, TextField } from "@mui/material";
//
import useTemplates from "../../../hooks/useTemplates";

// ----------------------------------------------------------------------

TemplateForm.propTypes = {
  isEdit: PropTypes.bool,
  currentTemplate: PropTypes.object,
};

export default function TemplateForm({ isEdit, currentTemplate }) {
  const { createTemplate, updateTemplate } = useTemplates();

  const NewTemplateSchema = Yup.object().shape({
    templateTitle: Yup.string().required("Title is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      templateTitle: currentTemplate?.templateTitle || "",
    },
    validationSchema: NewTemplateSchema,
    onSubmit: async (values) => {
      if (isEdit) {
        await updateTemplate(values, currentTemplate.templateId);
      } else {
        await createTemplate(values);
      }
    },
  });

  const {
    errors,
    // values,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Template Title"
                  {...getFieldProps("templateTitle")}
                  error={Boolean(touched.templateTitle && errors.templateTitle)}
                  helperText={touched.templateTitle && errors.templateTitle}
                />
                <LoadingButton
                  type="submit"
                  sx={{
                    width: 180,
                  }}
                  variant="contained"
                  size="large"
                  loading={isSubmitting}
                >
                  {!isEdit ? "Create Template" : "Save Changes"}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
