// scroll bar
import 'simplebar/src/simplebar.css';

// highlight
import './utils/highlight';

// // lightbox
// import 'react-image-lightbox/style.css';

// editor
import 'react-quill/dist/quill.snow.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// // slick-carousel
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// // lazy image
// import 'lazysizes';
// import 'lazysizes/plugins/attrchange/ls.attrchange';
// import 'lazysizes/plugins/object-fit/ls.object-fit';
// import 'lazysizes/plugins/parent-fit/ls.parent-fit';

// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
// components
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import NotistackProvider from "./components/NotistackProvider";
import { ProgressBarStyle } from "./components/LoadingScreen";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <NotistackProvider>
          <GlobalStyles />
          <ProgressBarStyle />
          <Router />
        </NotistackProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
