import PropTypes from "prop-types";
import { filter } from "lodash";
import { useState, useCallback } from "react";
// material
import {
  Box,
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography,
} from "@mui/material";
// utils
import { fDateTime } from "../../../../utils/formatTime";
// components
import Scrollbar from "../../../../components/Scrollbar";
import SearchNotFound from "../../../../components/SearchNotFound";
import TableHeader from "../../../../components/table/TableHeader";
import TableToolbar from "../../../../components/table/TableToolbar";
import QuestionsTableActions from "./QuestionsTableActions";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

QuestionsTable.propTypes = {
  list: PropTypes.array,
  tableHead: PropTypes.array,
  typeId: PropTypes.string,
};

export default function QuestionsTable({ list, tableHead, typeId }) {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderBy] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const id = list[0]
    ? Object.keys(list[0]).find((key) => key.slice(-2) === "Id")
    : "Id";

  const applySortFilter = useCallback((array, comparator, query) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    if (query) {
      return filter(
        array,
        (item) =>
          (item["question"].toLowerCase().includes(query.trim().toLowerCase())  || item[id].toLowerCase().includes(query.trim().toLowerCase()))
      );
    }

    return stabilizedThis.map((el) => el[0]);
  }, [id]);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

  const filteredRows = applySortFilter(
    list,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = filteredRows.length === 0;

  return (
    <Card>
      <TableToolbar filterName={filterName} onFilterName={handleFilterByName} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHeader
              order={order}
              orderBy={orderBy}
              headLabel={tableHead}
              rowCount={list.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover key={row[id]}>
                      <TableCell component="th" scope="row" padding="none">
                        <Typography variant="subtitle2" noWrap>
                          {row["questionCategory"].questionCategoryTitle}
                        </Typography>
                        <Typography  sx={{fontSize: '10px'}}>
                          ID:&nbsp;{row[id]}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <div
                          dangerouslySetInnerHTML={{ __html: row["question"] }}
                        />
                      </TableCell>
                      <TableCell>{row["questionType"]}</TableCell>
                      <TableCell style={{ minWidth: 160 }}>
                        {fDateTime(row["createdAt"])}
                      </TableCell>
                      <TableCell style={{ minWidth: 160 }}>
                        {fDateTime(row["updatedAt"])}
                      </TableCell>
                      <TableCell align="right">
                        <QuestionsTableActions
                          id={row[id]}
                          currentQuestion={{
                            question: row["question"],
                            questionType: row["questionType"],
                            questionCategory: row["questionCategory"],
                          }}
                          typeId={typeId}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <Box sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
